/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

export interface CreateCompanySettingsDTO {
  /**
   * Has profit and loss
   * @example "true"
   */
  hasPL?: boolean
  /**
   * Has Overtime
   * @example "true"
   */
  hasOvertime?: boolean
  /**
   * Overtime multiplier
   * @example 1
   */
  overtimeMultiplier?: number
  /**
   * Has work from home
   * @example "false"
   */
  hasWFH?: boolean
}

export interface UpdateCompanyDTO {
  /**
   * Company name
   * @example "Moja firma"
   */
  name?: string
  /**
   * Company image
   * @example "/company/my-image.png"
   */
  image?: string
  /** Company settings */
  companySettings?: CreateCompanySettingsDTO
}

export interface CreateCompanyDTO {
  /**
   * Company name
   * @example "Moja firma"
   */
  name: string
  /**
   * Company image
   * @example "/company/my-image.png"
   */
  image?: string
  /** Company settings */
  companySettings?: CreateCompanySettingsDTO
}

export interface ChangeStatusDTO {
  /**
   * Activity status of the company
   * @example true
   */
  isActive: boolean
}

export interface ImportChangesEmployeeDTO {
  /**
   * Users first and last name
   * @example "Marcus Aurelius"
   */
  name: string
  /**
   * Users phone number
   * @example "099111111"
   */
  username?: string
  /**
   * Users email
   * @example "email@example.com"
   */
  email: string
  /**
   * Employees department1
   * @example "Mediji"
   */
  department1?: string
  /**
   * Employees role1
   * @example "Zaposlenik"
   */
  role1?: string
  /**
   * Employees department2
   * @example "Kreativa"
   */
  department2?: string
  /**
   * Employees role2
   * @example "Voditelj"
   */
  role2?: string
  /**
   * Employees arrival
   * @example "2021-01-01"
   */
  arrival?: string
  /**
   * Employees departure
   * @example "2021-01-01"
   */
  departure?: string
  /**
   * Employees hoursType
   * @example "FULLTIME"
   */
  hoursType?: string
  /**
   * Employees totalVacationDays
   * @example "20"
   */
  totalVacationDays?: string
  /**
   * Employees thisYearVacationDays
   * @example "20"
   */
  thisYearVacationDays?: string
  /**
   * Employees lastYearVacationDays
   * @example "20"
   */
  lastYearVacationDays?: string
  /**
   * Employees salaryChange
   * @example "NE"
   */
  salaryChange?: string
  /**
   * Employees salaryDate
   * @example "2021-01-01"
   */
  salaryDate?: string
  /**
   * Employees bruto2
   * @example "1000"
   */
  bruto2?: string
  /**
   * Employees neto
   * @example "800"
   */
  neto?: string
  /**
   * Employees transport
   * @example "100"
   */
  transport?: string
  /**
   * Employees fuel
   * @example "100"
   */
  fuel?: string
  /**
   * Employees parking
   * @example "100"
   */
  parking?: string
  /**
   * Employees other
   * @example "100"
   */
  other?: string
  /**
   * Employees kind
   * @example "100"
   */
  kind?: string
}

export interface EmployeeImportChangesDto {
  toBeDeleted: ImportChangesEmployeeDTO[][]
  toBeAdded: ImportChangesEmployeeDTO[][]
  toBeUpdated: ImportChangesEmployeeDTO[][]
  all: ImportChangesEmployeeDTO[][]
}

export interface CreateEmployeeDepartmentDTO {
  /**
   * Department id
   * @example 1
   */
  id: number
  /**
   * Is department manager
   * @example true
   */
  isManager: boolean
}

export interface UpdateEmployeeDTO {
  /**
   * Employee name
   * @example "Ivica Ivić"
   */
  name?: string
  /**
   * Employee email
   * @example "ivica.ivic@gmail.com"
   */
  email?: string
  /**
   * Employee code
   * @example "Ivica-123"
   */
  code?: string
  /**
   * Departments in which employee works
   * @example [{"id":1,"isManager":true}]
   */
  departments?: CreateEmployeeDepartmentDTO[]
  /**
   * Employee departure date
   * @example "2025-02-11T11:35:47.770Z"
   */
  departure?: string
  /**
   * Employee arrival date
   * @example "2025-02-11T11:35:47.770Z"
   */
  arrival?: string
  /**
   * Employee hours type
   * @example "FULLTIME"
   */
  hoursType?: UpdateEmployeeDtoHoursTypeEnum
}

export interface CreateEmployeeSalaryDTO {
  /**
   * Salary bruto2
   * @example 12500.5
   */
  bruto2: number
  /**
   * Salary neto
   * @example 12500.5
   */
  neto?: number
  /**
   * Salary transport
   * @example 360.54
   */
  transport?: number
  /**
   * Salary fuel and toll
   * @example 564.3
   */
  fuel?: number
  /**
   * Salary parking
   * @example 12500.5
   */
  parking?: number
  /**
   * Salary other
   * @example 12500.5
   */
  other?: number
  /**
   * Salary in kind
   * @example 12500.5
   */
  kind?: number
  /**
   * Expense description
   * @example ""
   */
  description?: string
  /**
   * Expense date
   * @example "2022-12-10"
   */
  date: string
}

export interface CreateEmployeeDTO {
  /**
   * Employee name
   * @example "Ivica Ivić"
   */
  name: string
  /**
   * Employee email
   * @example "ivica.ivic@gmail.com"
   */
  email: string
  /**
   * Employee code
   * @example "Ivica-123"
   */
  code?: string
  /**
   * Departments in which employee works
   * @example [{"id":1,"isManager":true}]
   */
  departments: CreateEmployeeDepartmentDTO[]
  /**
   * Employee salary
   * @example [{"bruto2":500,"neto":300,"transport":40}]
   */
  salary: CreateEmployeeSalaryDTO
  /**
   * Employee departure date
   * @example "2025-02-11T11:35:47.770Z"
   */
  departure?: string
  /**
   * Employee arrival date
   * @example "2025-02-11T11:35:47.770Z"
   */
  arrival?: string
  /**
   * Employee hours type
   * @example "FULLTIME"
   */
  hoursType: CreateEmployeeDtoHoursTypeEnum
}

export interface ImportChangesSalaryDTO {
  /**
   * Users first and last name
   * @example "Marcus Aurelius"
   */
  name: string
  /**
   * Salary date
   * @example "2021-01-15"
   */
  date?: string
  /**
   * Bruto2
   * @example "1000"
   */
  bruto2?: string
  /**
   * Neto
   * @example "800"
   */
  neto?: string
  /**
   * Transport
   * @example "100"
   */
  transport?: string
  /**
   * Fuel
   * @example "100"
   */
  fuel?: string
  /**
   * Parking
   * @example "100"
   */
  parking?: string
  /**
   * Other
   * @example "100"
   */
  other?: string
  /**
   * Kind
   * @example "100"
   */
  kind?: string
}

export interface SalaryImportChangesDto {
  toBeDeleted: ImportChangesSalaryDTO[][]
  toBeAdded: ImportChangesSalaryDTO[][]
  toBeUpdated: ImportChangesSalaryDTO[][]
  all: ImportChangesSalaryDTO[][]
}

export interface UpdateSalaryDTO {
  /**
   * Salary bruto2
   * @example 12500.5
   */
  bruto2?: number
  /**
   * Salary neto
   * @example 12500.5
   */
  neto?: number
  /**
   * Salary transport
   * @example 360.54
   */
  transport?: number
  /**
   * Salary fuel and toll
   * @example 564.3
   */
  fuel?: number
  /**
   * Salary parking
   * @example 12500.5
   */
  parking?: number
  /**
   * Salary other
   * @example 12500.5
   */
  other?: number
  /**
   * Salary in kind
   * @example 12500.5
   */
  kind?: number
  /**
   * Employee id
   * @example 12
   */
  employeeId?: number
  /**
   * Expense description
   * @example ""
   */
  description?: string
  /**
   * Expense date
   * @example "2022-12-10"
   */
  date?: string
}

export interface CreateSalaryDTO {
  /**
   * Salary bruto2
   * @example 12500.5
   */
  bruto2: number
  /**
   * Salary neto
   * @example 12500.5
   */
  neto?: number
  /**
   * Salary transport
   * @example 360.54
   */
  transport?: number
  /**
   * Salary fuel and toll
   * @example 564.3
   */
  fuel?: number
  /**
   * Salary parking
   * @example 12500.5
   */
  parking?: number
  /**
   * Salary other
   * @example 12500.5
   */
  other?: number
  /**
   * Salary in kind
   * @example 12500.5
   */
  kind?: number
  /**
   * Employee id
   * @example 12
   */
  employeeId: number
  /**
   * Expense description
   * @example ""
   */
  description?: string
  /**
   * Expense date
   * @example "2022-12-10"
   */
  date: string
}

export interface SignInDTO {
  /**
   * Users username or string
   * @example "email@example.com"
   */
  username: string
  /**
   * Users password
   * @minLength 8
   * @example "Password1!"
   */
  password: string
}

export interface ResetPasswordDTO {
  /**
   * Users new password
   * @minLength 8
   * @example "Password1!"
   */
  password: string
  /**
   * Users new password confirmation
   * @minLength 8
   * @example "Password1!"
   */
  confirmPassword: string
  /**
   * Users token
   * @example "7e6ea83f-85f6-498d-81ef-0bd2eb1b4fa7"
   */
  token: string
}

export interface ForgotPasswordDTO {
  /**
   * Users username or email
   * @example "email@example.com"
   */
  username: string
}

export interface UpdateUserDTO {
  /**
   * Users name
   * @example "Marcus Aurelius"
   */
  name?: string
  /**
   * Users email
   * @example "email@example.com"
   */
  email?: string
  /**
   * Users username
   * @example "prezenter1"
   */
  username?: string
  /**
   * Users role
   * @example "ADMIN"
   */
  role?: UpdateUserDtoRoleEnum
  /**
   * Users active state
   * @example true
   */
  isActive?: boolean
  /**
   * Company id
   * @example 4
   */
  companyId?: number
  /**
   * Users image
   * @example "/user.png"
   */
  image?: string
  /**
   * Users password
   * @example "password1234"
   */
  password?: string
  /**
   * Users new password confirmation
   * @minLength 8
   * @example "Password1!"
   */
  confirmPassword?: string
  enableMailNotifications?: boolean
}

export interface CreateUserDTO {
  /**
   * Users name
   * @example "Marcus Aurelius"
   */
  name: string
  /**
   * Users email
   * @example "email@example.com"
   */
  email?: string
  /**
   * Users username
   * @example "prezenter1"
   */
  username?: string
  /**
   * Users role
   * @example "ADMIN"
   */
  role?: CreateUserDtoRoleEnum
  /**
   * Users active state
   * @example true
   */
  isActive: boolean
  /**
   * Company id
   * @example 4
   */
  companyId: number
  /**
   * Users image
   * @example "/user.png"
   */
  image?: string
  /**
   * Users password
   * @example "password1234"
   */
  password?: string
}

export interface UpdateDepartmentDTO {
  /**
   * Department name
   * @example "Media"
   */
  name?: string
  /**
   * Parent id
   * @example 4
   */
  parentId?: number
  /**
   * Is operational department
   * @example true
   */
  operational?: boolean
  /**
   * Is support department
   * @example true
   */
  support?: boolean
  /**
   * Is channels department
   * @example true
   */
  channels?: boolean
}

export interface CreateDepartmentDTO {
  /**
   * Department name
   * @example "Media"
   */
  name: string
  /**
   * Parent id
   * @example 4
   */
  parentId: number
  /**
   * Is operational department
   * @example true
   */
  operational?: boolean
  /**
   * Is support department
   * @example true
   */
  support?: boolean
  /**
   * Is channels department
   * @example true
   */
  channels?: boolean
}

export interface EmployeeHour {
  /**
   * Employee name
   * @example "Ivan Ivić"
   */
  employee: string
  /**
   * Employee working hour percentage
   * @example 0.2
   */
  percentage: number
}

export interface WorkingHour {
  /**
   * Client name
   * @example "Kaufland"
   */
  client: string
  /**
   * Employee hours
   * @example [{"percentage":20,"clientId":5,"employeeId":10}]
   */
  employees?: EmployeeHour[]
}

export interface UpdateWorkingHoursDTO {
  /**
   * Working hours
   * @example [{"percentage":20,"clientId":5,"employeeId":10}]
   */
  hours?: WorkingHour[]
  /**
   * Working hours date
   * @example "2022-12-10"
   */
  date?: string
  /**
   * Department snowflake
   * @example "mediji_promo"
   */
  department?: string
}

export interface CreateWorkingHoursDTO {
  /**
   * Working hours
   * @example [{"percentage":20,"clientId":5,"employeeId":10}]
   */
  hours?: WorkingHour[]
  /**
   * Working hours date
   * @example "2022-12-10"
   */
  date: string
  /**
   * Department snowflake
   * @example "mediji_promo"
   */
  department: string
}

export interface WorkingHoursStatusDTO {
  /** @example 7880 */
  id: number
  /**
   * ISO format date
   * @example "2023-01-15"
   */
  date: string
  /**
   * Status of the working hours
   * @example "APPROVED"
   */
  status: string
  /**
   * Date when the status was sent
   * @example "2023-02-01"
   */
  date_sent: string
  /**
   * Date when the status was approved
   * @example "2023-02-05"
   */
  date_approved: string
  /**
   * Additional notes
   * @example "Rejected because of something"
   */
  note: string | null
  /**
   * Employee ID associated with the status
   * @example 205
   */
  employee_id: number
  /**
   * Total hours logged
   * @example 167
   */
  hours: number
}

export interface WorkingHoursMonthDto {
  type: WorkingHoursMonthDtoTypeEnum
  id: number
  working_hours_id: number
  name: string
  hours: number
}

export interface DepartmentDto {
  /** Employee id */
  id: number
  /** Employee name */
  name: string
}

export interface EmployeeDto {
  /** Employee id */
  id: number
  /** Employee name */
  name: string
}

export interface WorkingHoursStatusDto {
  workingHours: WorkingHoursMonthDto[]
  id: number
  /** @format date-time */
  date: string
  department: DepartmentDto
  status: WorkingHoursStatusDtoStatusEnum
  /** @format date-time */
  dateSent: string
  /** @format date-time */
  dateApproved: string
  note: string
  employee: EmployeeDto
}

export interface NonWorkingHoursStatusDto {
  id: number
  type: NonWorkingHoursStatusDtoTypeEnum
  hours: number
  employee_id: number
}

export interface StatusDTO {
  workingHours: WorkingHoursStatusDto[]
  nonWorkingHours: NonWorkingHoursStatusDto[]
}

export interface AddWorkingHourDTO {
  /**
   * Type of working hour
   * @example "NORMAL"
   */
  type?: AddWorkingHourDtoTypeEnum
  /**
   * Number of working hours
   * @example "40"
   */
  hours: number
  /**
   * Working hour month
   * @example "10"
   */
  month: number
  /**
   * Working hour employee id
   * @example "10"
   */
  employeeId: number
  /**
   * Working hour year
   * @example "2023"
   */
  year: number
  /**
   * Working hour client id
   * @example "5"
   */
  clientId?: number
  /**
   * Working hour department id
   * @example "11"
   */
  departmentId?: number
}

export interface UpdateWorkingHoursTypeDTO {
  /**
   * Type of working hour
   * @example "NORMAL"
   */
  type?: UpdateWorkingHoursTypeDtoTypeEnum
  /**
   * Number of working hours
   * @example "40"
   */
  hours?: number
  /**
   * Working hour month
   * @example "10"
   */
  month?: number
  /**
   * Working hour employee id
   * @example "10"
   */
  employeeId?: number
  /**
   * Working hour year
   * @example "2023"
   */
  year?: number
  /**
   * Working hour client id
   * @example "5"
   */
  clientId?: number
  /**
   * Working hour department id
   * @example "11"
   */
  departmentId?: number
}

export interface RejectWorkingHourStatus {
  /**
   * Working hour status
   * @example "Rejected because of something"
   */
  note?: string
}

export interface SendStatusToManagerDTO {
  /**
   * Year of the working hours
   * @example 2023
   */
  year: number
  /**
   * Month of the working hours
   * @example 1
   */
  month: number
  /**
   * Employee ID
   * @example 1
   */
  employeeId: number
}

export interface StatusIdsDTO {
  /**
   * Array of status IDs
   * @example [1,2,3]
   */
  ids: string[]
}

export interface UpdateClientDTO {
  /**
   * Client name
   * @example "Moj klijent"
   */
  name?: string
  /**
   * Client id to update
   * @example 11
   */
  id: number
  /**
   * Is client active
   * @example true
   */
  isActive?: boolean
}

export interface CreateClientDTO {
  /**
   * Client name
   * @example "Moj klijent"
   */
  name: string
}

export interface UpdateUserCompanyDTO {
  /**
   * Users role
   * @example "ADMIN"
   */
  role?: UpdateUserCompanyDtoRoleEnum
  /**
   * Users active state
   * @example true
   */
  isActive?: boolean
  /**
   * User id
   * @example 4
   */
  userId?: number
  /**
   * Company id
   * @example 4
   */
  companyId?: number
}

export interface CreateUserCompanyDTO {
  /**
   * Users role
   * @example "ADMIN"
   */
  role?: CreateUserCompanyDtoRoleEnum
  /**
   * Users active state
   * @example true
   */
  isActive: boolean
  /**
   * User id
   * @example 4
   */
  userId: number
  /**
   * Company id
   * @example 4
   */
  companyId: number
}

export interface UpdateCompanyplDTO {
  /**
   * PL id
   * @example 2
   */
  id?: number
  /**
   * PL special_projects
   * @example 20.54
   */
  special_projects?: number
  /**
   * PL sale_goods
   * @example 20.54
   */
  sale_goods?: number
  /**
   * PL extraordinary_income
   * @example 20.54
   */
  extraordinary_income?: number
  /**
   * PL other_income
   * @example 20.54
   */
  other_income?: number
  /**
   * PL special_projects_costs
   * @example 20.54
   */
  special_projects_costs?: number
  /**
   * PL representation
   * @example 20.54
   */
  representation?: number
  /**
   * PL office_materials
   * @example 20.54
   */
  office_materials?: number
  /**
   * PL consulting_costs
   * @example 20.54
   */
  consulting_costs?: number
  /**
   * PL lease
   * @example 20.54
   */
  lease?: number
  /**
   * PL memberships
   * @example 20.54
   */
  memberships?: number
  /**
   * PL goods_procurement
   * @example 20.54
   */
  goods_procurement?: number
  /**
   * PL travel
   * @example 20.54
   */
  travel?: number
  /**
   * PL write_offs
   * @example 20.54
   */
  write_offs?: number
  /**
   * PL education
   * @example 20.54
   */
  education?: number
  /**
   * PL bank_insurance_costs
   * @example 20.54
   */
  bank_insurance_costs?: number
  /**
   * PL other_costs
   * @example 20.54
   */
  other_costs?: number
  /**
   * PL amortization
   * @example 20.54
   */
  amortization?: number
  /**
   * PL financial_revenue
   * @example 20.54
   */
  financial_revenue?: number
  /**
   * PL financial_expenses
   * @example 20.54
   */
  financial_expenses?: number
  /**
   * PL IsNumb
   * @example 20.54
   */
  profit_tax?: number
  /**
   * PL date
   * @format date-time
   * @example "2023-02-19"
   */
  date?: string
}

export interface CreateCompanyplDTO {
  /**
   * PL id
   * @example 2
   */
  id?: number
  /**
   * PL special_projects
   * @example 20.54
   */
  special_projects?: number
  /**
   * PL sale_goods
   * @example 20.54
   */
  sale_goods?: number
  /**
   * PL extraordinary_income
   * @example 20.54
   */
  extraordinary_income?: number
  /**
   * PL other_income
   * @example 20.54
   */
  other_income?: number
  /**
   * PL special_projects_costs
   * @example 20.54
   */
  special_projects_costs?: number
  /**
   * PL representation
   * @example 20.54
   */
  representation?: number
  /**
   * PL office_materials
   * @example 20.54
   */
  office_materials?: number
  /**
   * PL consulting_costs
   * @example 20.54
   */
  consulting_costs?: number
  /**
   * PL lease
   * @example 20.54
   */
  lease?: number
  /**
   * PL memberships
   * @example 20.54
   */
  memberships?: number
  /**
   * PL goods_procurement
   * @example 20.54
   */
  goods_procurement?: number
  /**
   * PL travel
   * @example 20.54
   */
  travel?: number
  /**
   * PL write_offs
   * @example 20.54
   */
  write_offs?: number
  /**
   * PL education
   * @example 20.54
   */
  education?: number
  /**
   * PL bank_insurance_costs
   * @example 20.54
   */
  bank_insurance_costs?: number
  /**
   * PL other_costs
   * @example 20.54
   */
  other_costs?: number
  /**
   * PL amortization
   * @example 20.54
   */
  amortization?: number
  /**
   * PL financial_revenue
   * @example 20.54
   */
  financial_revenue?: number
  /**
   * PL financial_expenses
   * @example 20.54
   */
  financial_expenses?: number
  /**
   * PL IsNumb
   * @example 20.54
   */
  profit_tax?: number
  /**
   * PL date
   * @format date-time
   * @example "2023-02-19"
   */
  date: string
}

export interface DepartmentAmount {
  /**
   * Income amount
   * @example 20.54
   */
  amount: number
  /**
   * Department snowflake
   * @example "mediji"
   */
  department: string
}

export interface ClientAmount {
  /**
   * Per department income amounts
   * @example [{"department":"mediji","amount":1144.54}]
   */
  amounts?: DepartmentAmount[]
  /**
   * Client snowflake
   * @example "supersport"
   */
  client: string
}

export interface CreateIncomesDTO {
  /**
   * Per client income amounts
   * @example [{"client":"Kaufland","amount":[{"department":"mediji","amount":1144.54}]}]
   */
  amounts?: ClientAmount[]
  /**
   * Income date
   * @example "2022-12-10"
   */
  date: string
}

export interface CreateExpensesDTO {
  /**
   * Per client expense amounts
   * @example [{"amount":20,"departmentId":5,"clientId":10}]
   */
  amounts?: ClientAmount[]
  /**
   * Expense date
   * @example "2022-12-10"
   */
  date: string
}

export interface UpdateFaqDTO {
  /**
   * Faq language
   * @example "hr"
   */
  language?: string
  /**
   * Faq question
   * @example "What is company?"
   */
  question?: string
  /**
   * Faq answer
   * @example "Hur dur"
   */
  answer?: string
}

export interface CreateFaqDTO {
  /**
   * Faq language
   * @example "hr"
   */
  language: string
  /**
   * Faq question
   * @example "What is company?"
   */
  question: string
  /**
   * Faq answer
   * @example "Hur dur"
   */
  answer: string
}

/**
 * Employee hours type
 * @example "FULLTIME"
 */
export enum UpdateEmployeeDtoHoursTypeEnum {
  FULLTIME = 'FULLTIME',
  HALFTIME = 'HALFTIME',
  STUDENT = 'STUDENT',
}

/**
 * Employee hours type
 * @example "FULLTIME"
 */
export enum CreateEmployeeDtoHoursTypeEnum {
  FULLTIME = 'FULLTIME',
  HALFTIME = 'HALFTIME',
  STUDENT = 'STUDENT',
}

/**
 * Users role
 * @example "ADMIN"
 */
export enum UpdateUserDtoRoleEnum {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}

/**
 * Users role
 * @example "ADMIN"
 */
export enum CreateUserDtoRoleEnum {
  USER = 'USER',
  ADMIN = 'ADMIN',
  SUPERADMIN = 'SUPERADMIN',
}

export enum WorkingHoursMonthDtoTypeEnum {
  NORMAL = 'NORMAL',
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  WFH = 'WFH',
  REST = 'REST',
  VACATION = 'VACATION',
  OVERTIME = 'OVERTIME',
}

export enum WorkingHoursStatusDtoStatusEnum {
  NOTSENT = 'NOT SENT',
  SENT = 'SENT',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
}

export enum NonWorkingHoursStatusDtoTypeEnum {
  NORMAL = 'NORMAL',
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  WFH = 'WFH',
  REST = 'REST',
  VACATION = 'VACATION',
  OVERTIME = 'OVERTIME',
}

/**
 * Type of working hour
 * @example "NORMAL"
 */
export enum AddWorkingHourDtoTypeEnum {
  NORMAL = 'NORMAL',
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  WFH = 'WFH',
  REST = 'REST',
  VACATION = 'VACATION',
  OVERTIME = 'OVERTIME',
}

/**
 * Type of working hour
 * @example "NORMAL"
 */
export enum UpdateWorkingHoursTypeDtoTypeEnum {
  NORMAL = 'NORMAL',
  MATERNITY = 'MATERNITY',
  SICK = 'SICK',
  WFH = 'WFH',
  REST = 'REST',
  VACATION = 'VACATION',
  OVERTIME = 'OVERTIME',
}

/**
 * Users role
 * @example "ADMIN"
 */
export enum UpdateUserCompanyDtoRoleEnum {
  ADMIN = 'ADMIN',
  AUTHOR = 'AUTHOR',
  SUPERADMIN = 'SUPERADMIN',
}

/**
 * Users role
 * @example "ADMIN"
 */
export enum CreateUserCompanyDtoRoleEnum {
  ADMIN = 'ADMIN',
  AUTHOR = 'AUTHOR',
  SUPERADMIN = 'SUPERADMIN',
}

export type EmailControllerSendTestEmailData = any

export interface EmailControllerSendOnboardingMailToSpecificUsersParams {
  userIds: string
}

export type EmailControllerSendOnboardingMailToSpecificUsersData = any

export type EmailControllerSendOnboardingMailData = any

export type CompanyControllerGetAllCompanysData = any

export type CompanyControllerCreateCompanyData = any

export type CompanyControllerGetCompanyByIdData = any

export type CompanyControllerUpdateCompanyData = any

export type CompanyControllerDeleteCompanyData = any

export type CompanyControllerChangeCompanyStatusData = any

export type EmployeeControllerGetAllCompanyClientsData = any

export type EmployeeControllerCreateEmployeeData = any

export type EmployeeControllerExportUsersData = any

export interface EmployeeControllerCheckImportTemplatePayload {
  /** @format binary */
  file?: File
}

export type EmployeeControllerCheckImportTemplateData = any

export type EmployeeControllerImportTemplateData = any

export type EmployeeControllerGetEmployeeByIdData = any

export type EmployeeControllerUpdateEmployeeData = any

export type EmployeeControllerDeleteEmployeeData = any

export type SalaryControllerGetAllSalarysData = any

export type SalaryControllerCreateSalaryData = any

export type SalaryControllerExportUsersData = any

export interface SalaryControllerCheckImportTemplatePayload {
  /** @format binary */
  file?: File
}

export type SalaryControllerCheckImportTemplateData = any

export type SalaryControllerImportTemplateData = any

export type SalaryControllerGetSalaryByIdData = any

export type SalaryControllerUpdateSalaryData = any

export type SalaryControllerDeleteSalaryData = any

export type SalaryControllerGetSalariesByEmployeeIdData = any

export type AuthControllerSignInData = any

export type AuthControllerSingOutData = any

export type AuthControllerResetPasswordData = any

export type AuthControllerForgotPasswordData = any

export type AuthControllerGetMeData = any

export type AuthControllerUpdateMeData = any

export type UserControllerInitiateUsersData = any

export interface UserControllerHasPasswordParams {
  identity: string
}

export type UserControllerHasPasswordData = any

export type UserControllerGetAllData = any

export type UserControllerCreateUserData = any

export type UserControllerGetOneByIdData = any

export type UserControllerUpdateUserData = any

export type DepartmentControllerGetAllCompanyDepartmentsData = any

export type DepartmentControllerCreateDepartmentData = any

export type DepartmentControllerGetDepartmentByIdData = any

export type DepartmentControllerUpdateDepartmentData = any

export type DepartmentControllerDeleteDepartmentData = any

export type DepartmentControllerGetDepartmentByTypeData = any

export type DepartmentControllerSetManagerData = any

export type WorkingHoursControllerGetAllCompanyWorkingHoursData = any

export type WorkingHoursControllerDeleteAllCompanyWorkingHoursData = any

export type WorkingHoursControllerUpdateWorkingHoursData = any

export type WorkingHoursControllerCreateWorkingHoursData = any

export interface WorkingHoursControllerGetWorkingHoursByMonthParams {
  year: number
  month: number
}

export type WorkingHoursControllerGetWorkingHoursByMonthData = any

export interface WorkingHoursControllerGetAvailableHoursInMonthParams {
  year: number
  month: number
}

export type WorkingHoursControllerGetAvailableHoursInMonthData = any

export type WorkingHoursControllerGetArchiveMonthsData = WorkingHoursStatusDTO[]

export type WorkingHoursControllerGetActiveMonthsData = WorkingHoursStatusDTO[]

export type WorkingHoursControllerGetEmployeeDepartmentsData = any

export type WorkingHoursControllerGetClientsData = any

export type WorkingHoursControllerGetWorkingHoursStatusData = WorkingHoursStatusDto

export type WorkingHoursControllerGetWorkingHoursStatusesByDateData = StatusDTO

export interface WorkingHoursControllerGetNonWorkingHoursByMonthParams {
  year: number
  month: number
}

export type WorkingHoursControllerGetNonWorkingHoursByMonthData = any

export type WorkingHoursControllerAddWorkingHourData = any

export type WorkingHoursControllerDeleteWorkingHourData = any

export type WorkingHoursControllerUpdateWorkingHourData = any

export type WorkingHoursControllerGetActiveMonthsManagerData = any

export type WorkingHoursControllerGetNotSentMonthsManagerData = any

export interface WorkingHoursControllerGetArchiveMonthsManagerParams {
  year: number
  departmentId: number
}

export type WorkingHoursControllerGetArchiveMonthsManagerData = any

export type WorkingHoursControllerApproveWorkingHourData = any

export type WorkingHoursControllerRejectWorkingHourData = any

export type WorkingHoursControllerGetManagerDepartmentsData = any

export interface WorkingHoursControllerGetManagerClientsParams {
  /** @format date-time */
  date: string
  departmentId: number
}

export type WorkingHoursControllerGetManagerClientsData = any

export interface WorkingHoursControllerGetExistingDatesParams {
  departmentId: number
}

export type WorkingHoursControllerGetExistingDatesData = any

export type WorkingHoursControllerSendStatusToManagerData = any

export type WorkingHoursControllerSendReminderData = any

export interface WorkingHoursControllerGenerateStatusesParams {
  month: number
  year?: number
}

export type WorkingHoursControllerGenerateStatusesData = any

export type ClientControllerGetAllCompanyClientsData = any

export type ClientControllerCreateClientData = any

export type ClientControllerGetClientByIdData = any

export type ClientControllerUpdateClientData = any

export type ClientControllerDeleteClientData = any

export interface HealthControllerCheckHealthData {
  /** @example "ok" */
  status?: string
  /** @example {"database":{"status":"up"}} */
  info?: Record<
    string,
    {
      status?: string
      [key: string]: any
    }
  >
  /** @example {} */
  error?: Record<
    string,
    {
      status?: string
      [key: string]: any
    }
  >
  /** @example {"database":{"status":"up"}} */
  details?: Record<
    string,
    {
      status?: string
      [key: string]: any
    }
  >
}

export type UserCompanyControllerGetAllUsercompanysData = any

export type UserCompanyControllerCreateUsercompanyData = any

export type UserCompanyControllerGetUsercompanyByIdData = any

export type UserCompanyControllerUpdateUsercompanyData = any

export type UserCompanyControllerDeleteUsercompanyData = any

export type CompanyplControllerGetAllCompanyplsData = any

export type CompanyplControllerCreateCompanyplData = any

export type CompanyplControllerGetCompanyplByIdData = any

export type CompanyplControllerUpdateCompanyplData = any

export type CompanyplControllerDeleteCompanyplData = any

export type CompanyplControllerGetComapnyPlBtDateData = any

export type IncomeControllerGetAllIncomesData = any

export type IncomeControllerCreateIncomesData = any

export type IncomeControllerGetAllCompanyIncomesData = any

export type IncomeControllerGetAllCompanyIncomesByDateData = any

export type IncomeControllerDeleteAllByDateData = any

export type ExpenseControllerGetAllExpensesData = any

export type ExpenseControllerCreateExpensesData = any

export type ExpenseControllerGetAllCompanyExpensesData = any

export type ExpenseControllerGetAllCompanyIncomesByDateData = any

export type ExpenseControllerDeleteAllByDateData = any

export type FaqControllerGetAllFaqsData = any

export type FaqControllerCreateFaqData = any

export type FaqControllerGetFaqByIdData = any

export type FaqControllerUpdateFaqData = any

export type FaqControllerDeleteFaqData = any

export type DashControllerIncomeAndCostsData = any

export interface DashControllerEmployeeAndClientParams {
  currency: number
}

export type DashControllerEmployeeAndClientData = any

export type DashControllerDepartmentReviewData = any

export interface DashControllerTopClientsParams {
  order: string
}

export type DashControllerTopClientsData = any

export type ClientControllerSummaryData = any

export type ClientControllerListData = any

export type ClientControllerClientSummaryData = any

export type ClientControllerClientOverviewData = any

export type ClientControllerYearCompareData = any

export type DepartmentControllerSummaryData = any

export type DepartmentControllerListData = any

export type DepartmentControllerCompareData = any

export type EmployeeControllerSummaryData = any

export type EmployeeControllerClientEmployeesData = any

export type EmployeeControllerClientDepartmentsData = any

export type EmployeeControllerEmployeeSummaryData = any

export type EmployeeControllerEmployeeClientDetailsData = any

export type EmployeeControllerVacationHoursData = any

export type EmployeeControllerMaternityHoursData = any

export type EmployeeControllerSickHoursData = any

export interface EmployeeControllerGetNumberOfEmployeesInDepartmentParams {
  year: number
  id: number
}

export type EmployeeControllerGetNumberOfEmployeesInDepartmentData = any

export type CompanyControllerSummaryData = any

export type CompanyControllerDetailsData = any

export type CompanyControllerYearCompareData = any

export namespace Email {
  /**
   * @description Route for sending test email
   * @tags email
   * @name EmailControllerSendTestEmail
   * @request GET:/email/test
   */
  export namespace EmailControllerSendTestEmail {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmailControllerSendTestEmailData
  }

  /**
   * @description Route for sending onboarding mail to specific users
   * @tags email
   * @name EmailControllerSendOnboardingMailToSpecificUsers
   * @request GET:/email/onboarding-mail/specific/{userIds}
   */
  export namespace EmailControllerSendOnboardingMailToSpecificUsers {
    export type RequestParams = {
      userIds: string
    }
    export type RequestQuery = {
      userIds: number[]
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmailControllerSendOnboardingMailToSpecificUsersData
  }

  /**
   * No description
   * @tags email
   * @name EmailControllerSendOnboardingMail
   * @request GET:/email/onboarding-mail/{companyId}
   */
  export namespace EmailControllerSendOnboardingMail {
    export type RequestParams = {
      companyId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmailControllerSendOnboardingMailData
  }
}

export namespace Company {
  /**
   * @description Public route for fetching all companys
   * @tags company
   * @name CompanyControllerGetAllCompanys
   * @request GET:/company
   */
  export namespace CompanyControllerGetAllCompanys {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerGetAllCompanysData
  }

  /**
   * @description Route for creating a company
   * @tags company
   * @name CompanyControllerCreateCompany
   * @request POST:/company
   * @secure
   */
  export namespace CompanyControllerCreateCompany {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateCompanyDTO
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerCreateCompanyData
  }

  /**
   * @description Public route for fetching a specific company
   * @tags company
   * @name CompanyControllerGetCompanyById
   * @request GET:/company/{id}
   */
  export namespace CompanyControllerGetCompanyById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerGetCompanyByIdData
  }

  /**
   * @description Route for updating a specific company
   * @tags company
   * @name CompanyControllerUpdateCompany
   * @request PATCH:/company/{id}
   * @secure
   */
  export namespace CompanyControllerUpdateCompany {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateCompanyDTO
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerUpdateCompanyData
  }

  /**
   * @description Route for deleting a specific company
   * @tags company
   * @name CompanyControllerDeleteCompany
   * @request DELETE:/company/{id}
   * @secure
   */
  export namespace CompanyControllerDeleteCompany {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerDeleteCompanyData
  }

  /**
   * @description Route for deleting a specific company
   * @tags company
   * @name CompanyControllerChangeCompanyStatus
   * @request PATCH:/company/{id}/status
   * @secure
   */
  export namespace CompanyControllerChangeCompanyStatus {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = ChangeStatusDTO
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerChangeCompanyStatusData
  }
}

export namespace Employee {
  /**
   * @description Public route for fetching all employees
   * @tags employee
   * @name EmployeeControllerGetAllCompanyClients
   * @request GET:/employee
   */
  export namespace EmployeeControllerGetAllCompanyClients {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerGetAllCompanyClientsData
  }

  /**
   * @description Route for creating a employee
   * @tags employee
   * @name EmployeeControllerCreateEmployee
   * @request POST:/employee
   * @secure
   */
  export namespace EmployeeControllerCreateEmployee {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateEmployeeDTO
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerCreateEmployeeData
  }

  /**
   * @description Public route for exporting specific content data
   * @tags employee
   * @name EmployeeControllerExportUsers
   * @request GET:/employee/export
   */
  export namespace EmployeeControllerExportUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerExportUsersData
  }

  /**
   * @description Public route for check import client template
   * @tags employee
   * @name EmployeeControllerCheckImportTemplate
   * @request POST:/employee/import/template/check
   */
  export namespace EmployeeControllerCheckImportTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EmployeeControllerCheckImportTemplatePayload
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerCheckImportTemplateData
  }

  /**
   * @description Public route for importing client template
   * @tags employee
   * @name EmployeeControllerImportTemplate
   * @request POST:/employee/import/template
   */
  export namespace EmployeeControllerImportTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = EmployeeImportChangesDto
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerImportTemplateData
  }

  /**
   * @description Public route for fetching a specific employee
   * @tags employee
   * @name EmployeeControllerGetEmployeeById
   * @request GET:/employee/{id}
   */
  export namespace EmployeeControllerGetEmployeeById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerGetEmployeeByIdData
  }

  /**
   * @description Route for updating a specific employee
   * @tags employee
   * @name EmployeeControllerUpdateEmployee
   * @request PATCH:/employee/{id}
   * @secure
   */
  export namespace EmployeeControllerUpdateEmployee {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateEmployeeDTO
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerUpdateEmployeeData
  }

  /**
   * @description Route for deleting a specific employee
   * @tags employee
   * @name EmployeeControllerDeleteEmployee
   * @request DELETE:/employee/{id}
   * @secure
   */
  export namespace EmployeeControllerDeleteEmployee {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerDeleteEmployeeData
  }
}

export namespace Salary {
  /**
   * @description Public route for fetching all salarys
   * @tags salary
   * @name SalaryControllerGetAllSalarys
   * @request GET:/salary
   */
  export namespace SalaryControllerGetAllSalarys {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerGetAllSalarysData
  }

  /**
   * @description Route for creating a salary
   * @tags salary
   * @name SalaryControllerCreateSalary
   * @request POST:/salary
   * @secure
   */
  export namespace SalaryControllerCreateSalary {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateSalaryDTO
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerCreateSalaryData
  }

  /**
   * @description Public route for exporting specific content data
   * @tags salary
   * @name SalaryControllerExportUsers
   * @request GET:/salary/export
   */
  export namespace SalaryControllerExportUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerExportUsersData
  }

  /**
   * @description Public route for check import client template
   * @tags salary
   * @name SalaryControllerCheckImportTemplate
   * @request POST:/salary/template/check
   */
  export namespace SalaryControllerCheckImportTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = SalaryControllerCheckImportTemplatePayload
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerCheckImportTemplateData
  }

  /**
   * @description Public route for importing client template
   * @tags salary
   * @name SalaryControllerImportTemplate
   * @request POST:/salary/template
   */
  export namespace SalaryControllerImportTemplate {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = SalaryImportChangesDto
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerImportTemplateData
  }

  /**
   * @description Public route for fetching a specific salary
   * @tags salary
   * @name SalaryControllerGetSalaryById
   * @request GET:/salary/{id}
   */
  export namespace SalaryControllerGetSalaryById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerGetSalaryByIdData
  }

  /**
   * @description Route for updating a specific salary
   * @tags salary
   * @name SalaryControllerUpdateSalary
   * @request PATCH:/salary/{id}
   * @secure
   */
  export namespace SalaryControllerUpdateSalary {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateSalaryDTO
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerUpdateSalaryData
  }

  /**
   * @description Route for deleting a specific salary
   * @tags salary
   * @name SalaryControllerDeleteSalary
   * @request DELETE:/salary/{id}
   * @secure
   */
  export namespace SalaryControllerDeleteSalary {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerDeleteSalaryData
  }

  /**
   * @description Public route for fetching salaries for specific employee
   * @tags salary
   * @name SalaryControllerGetSalariesByEmployeeId
   * @request GET:/salary/employee/{id}
   */
  export namespace SalaryControllerGetSalariesByEmployeeId {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = SalaryControllerGetSalariesByEmployeeIdData
  }
}

export namespace Auth {
  /**
   * No description
   * @tags auth
   * @name AuthControllerSignIn
   * @request POST:/auth/signin
   */
  export namespace AuthControllerSignIn {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = SignInDTO
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerSignInData
  }

  /**
   * No description
   * @tags auth
   * @name AuthControllerSingOut
   * @request GET:/auth/signout
   */
  export namespace AuthControllerSingOut {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerSingOutData
  }

  /**
   * No description
   * @tags auth
   * @name AuthControllerResetPassword
   * @request POST:/auth/reset-password
   */
  export namespace AuthControllerResetPassword {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ResetPasswordDTO
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerResetPasswordData
  }

  /**
   * No description
   * @tags auth
   * @name AuthControllerForgotPassword
   * @request POST:/auth/forgot-password
   */
  export namespace AuthControllerForgotPassword {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = ForgotPasswordDTO
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerForgotPasswordData
  }

  /**
   * No description
   * @tags auth
   * @name AuthControllerGetMe
   * @request GET:/auth/me
   */
  export namespace AuthControllerGetMe {
    export type RequestParams = {
      token: uuid
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerGetMeData
  }

  /**
   * No description
   * @tags auth
   * @name AuthControllerUpdateMe
   * @request PATCH:/auth/me
   */
  export namespace AuthControllerUpdateMe {
    export type RequestParams = {
      token: uuid
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateUserDTO
    export type RequestHeaders = {}
    export type ResponseBody = AuthControllerUpdateMeData
  }
}

export namespace User {
  /**
   * No description
   * @tags user
   * @name UserControllerInitiateUsers
   * @request GET:/user/initiate-users
   */
  export namespace UserControllerInitiateUsers {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerInitiateUsersData
  }

  /**
   * No description
   * @tags user
   * @name UserControllerHasPassword
   * @request GET:/user/has-password
   */
  export namespace UserControllerHasPassword {
    export type RequestParams = {}
    export type RequestQuery = {
      identity: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerHasPasswordData
  }

  /**
   * @description Public route for fetching all users
   * @tags user
   * @name UserControllerGetAll
   * @request GET:/user
   */
  export namespace UserControllerGetAll {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerGetAllData
  }

  /**
   * @description Admin route for creating a user
   * @tags user
   * @name UserControllerCreateUser
   * @request POST:/user
   * @secure
   */
  export namespace UserControllerCreateUser {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateUserDTO
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerCreateUserData
  }

  /**
   * @description Public route for fetching a specific users
   * @tags user
   * @name UserControllerGetOneById
   * @request GET:/user/{id}
   */
  export namespace UserControllerGetOneById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerGetOneByIdData
  }

  /**
   * @description Admin route for creating a user
   * @tags user
   * @name UserControllerUpdateUser
   * @request PATCH:/user/{id}
   * @secure
   */
  export namespace UserControllerUpdateUser {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = CreateUserDTO
    export type RequestHeaders = {}
    export type ResponseBody = UserControllerUpdateUserData
  }
}

export namespace Department {
  /**
   * @description Public route for fetching all departments
   * @tags department
   * @name DepartmentControllerGetAllCompanyDepartments
   * @request GET:/department
   */
  export namespace DepartmentControllerGetAllCompanyDepartments {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerGetAllCompanyDepartmentsData
  }

  /**
   * @description Route for creating a department
   * @tags department
   * @name DepartmentControllerCreateDepartment
   * @request POST:/department
   * @secure
   */
  export namespace DepartmentControllerCreateDepartment {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateDepartmentDTO
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerCreateDepartmentData
  }

  /**
   * @description Public route for fetching a specific department
   * @tags department
   * @name DepartmentControllerGetDepartmentById
   * @request GET:/department/{id}
   */
  export namespace DepartmentControllerGetDepartmentById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerGetDepartmentByIdData
  }

  /**
   * @description Route for updating a specific department
   * @tags department
   * @name DepartmentControllerUpdateDepartment
   * @request PATCH:/department/{id}
   * @secure
   */
  export namespace DepartmentControllerUpdateDepartment {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateDepartmentDTO
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerUpdateDepartmentData
  }

  /**
   * @description Route for deleting a specific department
   * @tags department
   * @name DepartmentControllerDeleteDepartment
   * @request DELETE:/department/{id}
   * @secure
   */
  export namespace DepartmentControllerDeleteDepartment {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerDeleteDepartmentData
  }

  /**
   * @description Public route for fetching a specific department
   * @tags department
   * @name DepartmentControllerGetDepartmentByType
   * @request GET:/department/type/{type}
   */
  export namespace DepartmentControllerGetDepartmentByType {
    export type RequestParams = {
      type: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerGetDepartmentByTypeData
  }

  /**
   * @description Route for setting a manager for a specific department
   * @tags department
   * @name DepartmentControllerSetManager
   * @request PATCH:/department/set-manager/{employeeId}/departmentId/{departmentId}
   */
  export namespace DepartmentControllerSetManager {
    export type RequestParams = {
      departmentId: number
      employeeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerSetManagerData
  }
}

export namespace WorkingHours {
  /**
   * @description Public route for fetching all WorkingHours
   * @tags workingHours
   * @name WorkingHoursControllerGetAllCompanyWorkingHours
   * @request GET:/working-hours/department/{department}/date/{date}
   */
  export namespace WorkingHoursControllerGetAllCompanyWorkingHours {
    export type RequestParams = {
      department: string
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetAllCompanyWorkingHoursData
  }

  /**
   * @description Public route for fetching all WorkingHours
   * @tags workingHours
   * @name WorkingHoursControllerDeleteAllCompanyWorkingHours
   * @request DELETE:/working-hours/department/{department}/date/{date}
   */
  export namespace WorkingHoursControllerDeleteAllCompanyWorkingHours {
    export type RequestParams = {
      department: string
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerDeleteAllCompanyWorkingHoursData
  }

  /**
   * @description Route for updating a specific workingHours
   * @tags workingHours
   * @name WorkingHoursControllerUpdateWorkingHours
   * @request PATCH:/working-hours/{id}
   * @secure
   */
  export namespace WorkingHoursControllerUpdateWorkingHours {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateWorkingHoursDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerUpdateWorkingHoursData
  }

  /**
   * @description Route for creating a workingHours
   * @tags workingHours
   * @name WorkingHoursControllerCreateWorkingHours
   * @request POST:/working-hours
   * @secure
   */
  export namespace WorkingHoursControllerCreateWorkingHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateWorkingHoursDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerCreateWorkingHoursData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetWorkingHoursByMonth
   * @request GET:/working-hours
   */
  export namespace WorkingHoursControllerGetWorkingHoursByMonth {
    export type RequestParams = {}
    export type RequestQuery = {
      year: number
      month: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetWorkingHoursByMonthData
  }

  /**
   * @description Public route for fetching number of available hours in a month
   * @tags workingHours
   * @name WorkingHoursControllerGetAvailableHoursInMonth
   * @request GET:/working-hours/total-hours
   */
  export namespace WorkingHoursControllerGetAvailableHoursInMonth {
    export type RequestParams = {}
    export type RequestQuery = {
      year: number
      month: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetAvailableHoursInMonthData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetArchiveMonths
   * @request GET:/working-hours/archive
   */
  export namespace WorkingHoursControllerGetArchiveMonths {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetArchiveMonthsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetActiveMonths
   * @request GET:/working-hours/active
   */
  export namespace WorkingHoursControllerGetActiveMonths {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetActiveMonthsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetEmployeeDepartments
   * @request GET:/working-hours/departments
   */
  export namespace WorkingHoursControllerGetEmployeeDepartments {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetEmployeeDepartmentsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetClients
   * @request GET:/working-hours/clients
   */
  export namespace WorkingHoursControllerGetClients {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetClientsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetWorkingHoursStatus
   * @request GET:/working-hours/status/{id}
   */
  export namespace WorkingHoursControllerGetWorkingHoursStatus {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetWorkingHoursStatusData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetWorkingHoursStatusesByDate
   * @request GET:/working-hours/status/{date}/employee/{employeeId}
   */
  export namespace WorkingHoursControllerGetWorkingHoursStatusesByDate {
    export type RequestParams = {
      employeeId: number
      /** @format date-time */
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetWorkingHoursStatusesByDateData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetNonWorkingHoursByMonth
   * @request GET:/working-hours/holiday
   */
  export namespace WorkingHoursControllerGetNonWorkingHoursByMonth {
    export type RequestParams = {}
    export type RequestQuery = {
      year: number
      month: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetNonWorkingHoursByMonthData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerAddWorkingHour
   * @request POST:/working-hours/add-working-hour
   */
  export namespace WorkingHoursControllerAddWorkingHour {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = AddWorkingHourDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerAddWorkingHourData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerDeleteWorkingHour
   * @request DELETE:/working-hours/id/{whTypeId}
   */
  export namespace WorkingHoursControllerDeleteWorkingHour {
    export type RequestParams = {
      whTypeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerDeleteWorkingHourData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerUpdateWorkingHour
   * @request PATCH:/working-hours/id/{whTypeId}
   */
  export namespace WorkingHoursControllerUpdateWorkingHour {
    export type RequestParams = {
      whTypeId: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateWorkingHoursTypeDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerUpdateWorkingHourData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetActiveMonthsManager
   * @request GET:/working-hours/manager/active/{departmentId}
   */
  export namespace WorkingHoursControllerGetActiveMonthsManager {
    export type RequestParams = {
      departmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetActiveMonthsManagerData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetNotSentMonthsManager
   * @request GET:/working-hours/manager/not-sent/{departmentId}
   */
  export namespace WorkingHoursControllerGetNotSentMonthsManager {
    export type RequestParams = {
      departmentId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetNotSentMonthsManagerData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetArchiveMonthsManager
   * @request GET:/working-hours/manager/archive/{departmentId}
   */
  export namespace WorkingHoursControllerGetArchiveMonthsManager {
    export type RequestParams = {
      departmentId: number
    }
    export type RequestQuery = {
      year: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetArchiveMonthsManagerData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerApproveWorkingHour
   * @request PATCH:/working-hours/manager/approve/{whStatusId}
   */
  export namespace WorkingHoursControllerApproveWorkingHour {
    export type RequestParams = {
      whStatusId: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerApproveWorkingHourData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerRejectWorkingHour
   * @request PATCH:/working-hours/manager/reject/{whStatusId}
   */
  export namespace WorkingHoursControllerRejectWorkingHour {
    export type RequestParams = {
      whStatusId: number
    }
    export type RequestQuery = {}
    export type RequestBody = RejectWorkingHourStatus
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerRejectWorkingHourData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetManagerDepartments
   * @request GET:/working-hours/manager/departments
   */
  export namespace WorkingHoursControllerGetManagerDepartments {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetManagerDepartmentsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetManagerClients
   * @request GET:/working-hours/manager/department/{departmentId}/clients
   */
  export namespace WorkingHoursControllerGetManagerClients {
    export type RequestParams = {
      departmentId: number
    }
    export type RequestQuery = {
      /** @format date-time */
      date: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetManagerClientsData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGetExistingDates
   * @request GET:/working-hours/existing-dates
   */
  export namespace WorkingHoursControllerGetExistingDates {
    export type RequestParams = {}
    export type RequestQuery = {
      departmentId: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGetExistingDatesData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerSendStatusToManager
   * @request POST:/working-hours/status/send
   */
  export namespace WorkingHoursControllerSendStatusToManager {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = SendStatusToManagerDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerSendStatusToManagerData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerSendReminder
   * @request POST:/working-hours/send-reminder
   */
  export namespace WorkingHoursControllerSendReminder {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = StatusIdsDTO
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerSendReminderData
  }

  /**
   * No description
   * @tags workingHours
   * @name WorkingHoursControllerGenerateStatuses
   * @request GET:/working-hours/generate-statuses
   */
  export namespace WorkingHoursControllerGenerateStatuses {
    export type RequestParams = {}
    export type RequestQuery = {
      month: number
      year?: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = WorkingHoursControllerGenerateStatusesData
  }
}

export namespace Client {
  /**
   * @description Public route for fetching all clients
   * @tags client
   * @name ClientControllerGetAllCompanyClients
   * @request GET:/client
   */
  export namespace ClientControllerGetAllCompanyClients {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerGetAllCompanyClientsData
  }

  /**
   * @description Route for creating a client
   * @tags client
   * @name ClientControllerCreateClient
   * @request POST:/client
   * @secure
   */
  export namespace ClientControllerCreateClient {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateClientDTO
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerCreateClientData
  }

  /**
   * @description Public route for fetching a specific client
   * @tags client
   * @name ClientControllerGetClientById
   * @request GET:/client/{id}
   */
  export namespace ClientControllerGetClientById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerGetClientByIdData
  }

  /**
   * @description Route for updating a specific client
   * @tags client
   * @name ClientControllerUpdateClient
   * @request PATCH:/client/{id}
   * @secure
   */
  export namespace ClientControllerUpdateClient {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateClientDTO
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerUpdateClientData
  }

  /**
   * @description Route for deleting a specific client
   * @tags client
   * @name ClientControllerDeleteClient
   * @request DELETE:/client/{id}
   * @secure
   */
  export namespace ClientControllerDeleteClient {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerDeleteClientData
  }
}

export namespace Health {
  /**
   * No description
   * @tags health
   * @name HealthControllerCheckHealth
   * @request GET:/health
   */
  export namespace HealthControllerCheckHealth {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = HealthControllerCheckHealthData
  }
}

export namespace UserCompany {
  /**
   * @description Public route for fetching all userCompanys
   * @tags UserCompany
   * @name UserCompanyControllerGetAllUsercompanys
   * @request GET:/user-company
   */
  export namespace UserCompanyControllerGetAllUsercompanys {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserCompanyControllerGetAllUsercompanysData
  }

  /**
   * @description Route for creating a userCompany
   * @tags UserCompany
   * @name UserCompanyControllerCreateUsercompany
   * @request POST:/user-company
   * @secure
   */
  export namespace UserCompanyControllerCreateUsercompany {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateUserCompanyDTO
    export type RequestHeaders = {}
    export type ResponseBody = UserCompanyControllerCreateUsercompanyData
  }

  /**
   * @description Public route for fetching a specific userCompany
   * @tags UserCompany
   * @name UserCompanyControllerGetUsercompanyById
   * @request GET:/user-company/{id}
   */
  export namespace UserCompanyControllerGetUsercompanyById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserCompanyControllerGetUsercompanyByIdData
  }

  /**
   * @description Route for updating a specific userCompany
   * @tags UserCompany
   * @name UserCompanyControllerUpdateUsercompany
   * @request PATCH:/user-company/{id}
   * @secure
   */
  export namespace UserCompanyControllerUpdateUsercompany {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateUserCompanyDTO
    export type RequestHeaders = {}
    export type ResponseBody = UserCompanyControllerUpdateUsercompanyData
  }

  /**
   * @description Route for deleting a specific userCompany
   * @tags UserCompany
   * @name UserCompanyControllerDeleteUsercompany
   * @request DELETE:/user-company/{id}
   * @secure
   */
  export namespace UserCompanyControllerDeleteUsercompany {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = UserCompanyControllerDeleteUsercompanyData
  }
}

export namespace Pl {
  /**
   * @description Public route for fetching all companypls
   * @tags companypl
   * @name CompanyplControllerGetAllCompanypls
   * @request GET:/pl
   */
  export namespace CompanyplControllerGetAllCompanypls {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerGetAllCompanyplsData
  }

  /**
   * @description Route for creating a companypl
   * @tags companypl
   * @name CompanyplControllerCreateCompanypl
   * @request POST:/pl
   * @secure
   */
  export namespace CompanyplControllerCreateCompanypl {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateCompanyplDTO
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerCreateCompanyplData
  }

  /**
   * @description Public route for fetching a specific companypl
   * @tags companypl
   * @name CompanyplControllerGetCompanyplById
   * @request GET:/pl/{id}
   */
  export namespace CompanyplControllerGetCompanyplById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerGetCompanyplByIdData
  }

  /**
   * @description Route for updating a specific companypl
   * @tags companypl
   * @name CompanyplControllerUpdateCompanypl
   * @request PATCH:/pl/{id}
   * @secure
   */
  export namespace CompanyplControllerUpdateCompanypl {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateCompanyplDTO
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerUpdateCompanyplData
  }

  /**
   * @description Route for deleting a specific companypl
   * @tags companypl
   * @name CompanyplControllerDeleteCompanypl
   * @request DELETE:/pl/{id}
   * @secure
   */
  export namespace CompanyplControllerDeleteCompanypl {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerDeleteCompanyplData
  }

  /**
   * @description Public route for fetching a specific companypl
   * @tags companypl
   * @name CompanyplControllerGetComapnyPlBtDate
   * @request GET:/pl/date/{date}
   */
  export namespace CompanyplControllerGetComapnyPlBtDate {
    export type RequestParams = {
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyplControllerGetComapnyPlBtDateData
  }
}

export namespace Income {
  /**
   * @description Public route for fetching all incomes
   * @tags income
   * @name IncomeControllerGetAllIncomes
   * @request GET:/income
   */
  export namespace IncomeControllerGetAllIncomes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = IncomeControllerGetAllIncomesData
  }

  /**
   * @description Route for creating incomes
   * @tags income
   * @name IncomeControllerCreateIncomes
   * @request POST:/income
   * @secure
   */
  export namespace IncomeControllerCreateIncomes {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateIncomesDTO
    export type RequestHeaders = {}
    export type ResponseBody = IncomeControllerCreateIncomesData
  }

  /**
   * @description Public route for fetching all incomes
   * @tags income
   * @name IncomeControllerGetAllCompanyIncomes
   * @request GET:/income/company/{id}
   */
  export namespace IncomeControllerGetAllCompanyIncomes {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = IncomeControllerGetAllCompanyIncomesData
  }

  /**
   * @description Public route for fetching all incomes at specified date
   * @tags income
   * @name IncomeControllerGetAllCompanyIncomesByDate
   * @request GET:/income/date/{date}
   */
  export namespace IncomeControllerGetAllCompanyIncomesByDate {
    export type RequestParams = {
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = IncomeControllerGetAllCompanyIncomesByDateData
  }

  /**
   * @description Public route for deleting all incomes at specified date
   * @tags income
   * @name IncomeControllerDeleteAllByDate
   * @request DELETE:/income/date/{date}
   */
  export namespace IncomeControllerDeleteAllByDate {
    export type RequestParams = {
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = IncomeControllerDeleteAllByDateData
  }
}

export namespace Expense {
  /**
   * @description Public route for fetching all expenses
   * @tags expense
   * @name ExpenseControllerGetAllExpenses
   * @request GET:/expense
   */
  export namespace ExpenseControllerGetAllExpenses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpenseControllerGetAllExpensesData
  }

  /**
   * @description Route for creating expenses
   * @tags expense
   * @name ExpenseControllerCreateExpenses
   * @request POST:/expense
   * @secure
   */
  export namespace ExpenseControllerCreateExpenses {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateExpensesDTO
    export type RequestHeaders = {}
    export type ResponseBody = ExpenseControllerCreateExpensesData
  }

  /**
   * @description Public route for fetching all expenses
   * @tags expense
   * @name ExpenseControllerGetAllCompanyExpenses
   * @request GET:/expense/company/{id}
   */
  export namespace ExpenseControllerGetAllCompanyExpenses {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpenseControllerGetAllCompanyExpensesData
  }

  /**
   * @description Public route for fetching all incomes at specified date
   * @tags expense
   * @name ExpenseControllerGetAllCompanyIncomesByDate
   * @request GET:/expense/date/{date}
   */
  export namespace ExpenseControllerGetAllCompanyIncomesByDate {
    export type RequestParams = {
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpenseControllerGetAllCompanyIncomesByDateData
  }

  /**
   * @description Public route for deleting all expenses at specified date
   * @tags expense
   * @name ExpenseControllerDeleteAllByDate
   * @request DELETE:/expense/date/{date}
   */
  export namespace ExpenseControllerDeleteAllByDate {
    export type RequestParams = {
      date: string
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ExpenseControllerDeleteAllByDateData
  }
}

export namespace Faq {
  /**
   * @description Public route for fetching all faqs
   * @tags faq
   * @name FaqControllerGetAllFaqs
   * @request GET:/faq
   */
  export namespace FaqControllerGetAllFaqs {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FaqControllerGetAllFaqsData
  }

  /**
   * @description Route for creating a faq
   * @tags faq
   * @name FaqControllerCreateFaq
   * @request POST:/faq
   * @secure
   */
  export namespace FaqControllerCreateFaq {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = CreateFaqDTO
    export type RequestHeaders = {}
    export type ResponseBody = FaqControllerCreateFaqData
  }

  /**
   * @description Public route for fetching a specific faq
   * @tags faq
   * @name FaqControllerGetFaqById
   * @request GET:/faq/{id}
   */
  export namespace FaqControllerGetFaqById {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FaqControllerGetFaqByIdData
  }

  /**
   * @description Route for updating a specific faq
   * @tags faq
   * @name FaqControllerUpdateFaq
   * @request PATCH:/faq/{id}
   * @secure
   */
  export namespace FaqControllerUpdateFaq {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = UpdateFaqDTO
    export type RequestHeaders = {}
    export type ResponseBody = FaqControllerUpdateFaqData
  }

  /**
   * @description Route for deleting a specific faq
   * @tags faq
   * @name FaqControllerDeleteFaq
   * @request DELETE:/faq/{id}
   * @secure
   */
  export namespace FaqControllerDeleteFaq {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = FaqControllerDeleteFaqData
  }
}

export namespace Stats {
  /**
   * No description
   * @tags dash
   * @name DashControllerIncomeAndCosts
   * @request GET:/stats/dash/income-and-costs
   */
  export namespace DashControllerIncomeAndCosts {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DashControllerIncomeAndCostsData
  }

  /**
   * No description
   * @tags dash
   * @name DashControllerEmployeeAndClient
   * @request GET:/stats/dash/employee-and-client
   */
  export namespace DashControllerEmployeeAndClient {
    export type RequestParams = {}
    export type RequestQuery = {
      currency: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DashControllerEmployeeAndClientData
  }

  /**
   * No description
   * @tags dash
   * @name DashControllerDepartmentReview
   * @request GET:/stats/dash/department-review
   */
  export namespace DashControllerDepartmentReview {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DashControllerDepartmentReviewData
  }

  /**
   * No description
   * @tags dash
   * @name DashControllerTopClients
   * @request GET:/stats/dash/top-clients
   */
  export namespace DashControllerTopClients {
    export type RequestParams = {}
    export type RequestQuery = {
      order: string
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DashControllerTopClientsData
  }

  /**
   * No description
   * @tags client
   * @name ClientControllerSummary
   * @request GET:/stats/client/summary
   */
  export namespace ClientControllerSummary {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerSummaryData
  }

  /**
   * No description
   * @tags client
   * @name ClientControllerList
   * @request GET:/stats/client/list
   */
  export namespace ClientControllerList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerListData
  }

  /**
   * No description
   * @tags client
   * @name ClientControllerClientSummary
   * @request GET:/stats/client/{id}/summary
   */
  export namespace ClientControllerClientSummary {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerClientSummaryData
  }

  /**
   * No description
   * @tags client
   * @name ClientControllerClientOverview
   * @request GET:/stats/client/{id}/overview
   */
  export namespace ClientControllerClientOverview {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerClientOverviewData
  }

  /**
   * No description
   * @tags client
   * @name ClientControllerYearCompare
   * @request GET:/stats/client/{id}/year-compare
   */
  export namespace ClientControllerYearCompare {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = ClientControllerYearCompareData
  }

  /**
   * No description
   * @tags department
   * @name DepartmentControllerSummary
   * @request GET:/stats/department/summary
   */
  export namespace DepartmentControllerSummary {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerSummaryData
  }

  /**
   * No description
   * @tags department
   * @name DepartmentControllerList
   * @request GET:/stats/department/list
   */
  export namespace DepartmentControllerList {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerListData
  }

  /**
   * No description
   * @tags department
   * @name DepartmentControllerCompare
   * @request GET:/stats/department/year-compare
   */
  export namespace DepartmentControllerCompare {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = DepartmentControllerCompareData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerSummary
   * @request GET:/stats/employee/summary
   */
  export namespace EmployeeControllerSummary {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerSummaryData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerClientEmployees
   * @request GET:/stats/employee/client-employees
   */
  export namespace EmployeeControllerClientEmployees {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerClientEmployeesData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerClientDepartments
   * @request GET:/stats/employee/client-departments
   */
  export namespace EmployeeControllerClientDepartments {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerClientDepartmentsData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerEmployeeSummary
   * @request GET:/stats/employee/{id}/summary
   */
  export namespace EmployeeControllerEmployeeSummary {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerEmployeeSummaryData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerEmployeeClientDetails
   * @request GET:/stats/employee/{id}/details
   */
  export namespace EmployeeControllerEmployeeClientDetails {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerEmployeeClientDetailsData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerVacationHours
   * @request GET:/stats/employee/vacation-hours
   */
  export namespace EmployeeControllerVacationHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerVacationHoursData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerMaternityHours
   * @request GET:/stats/employee/maternity-hours
   */
  export namespace EmployeeControllerMaternityHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerMaternityHoursData
  }

  /**
   * No description
   * @tags employee
   * @name EmployeeControllerSickHours
   * @request GET:/stats/employee/sick-hours
   */
  export namespace EmployeeControllerSickHours {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerSickHoursData
  }

  /**
   * @description Route for getting number of employees in a specific department
   * @tags employee
   * @name EmployeeControllerGetNumberOfEmployeesInDepartment
   * @request GET:/stats/employee/{id}/number-of-employees
   */
  export namespace EmployeeControllerGetNumberOfEmployeesInDepartment {
    export type RequestParams = {
      id: number
    }
    export type RequestQuery = {
      year: number
    }
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = EmployeeControllerGetNumberOfEmployeesInDepartmentData
  }

  /**
   * No description
   * @tags company
   * @name CompanyControllerSummary
   * @request GET:/stats/company/summary
   */
  export namespace CompanyControllerSummary {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerSummaryData
  }

  /**
   * No description
   * @tags company
   * @name CompanyControllerDetails
   * @request GET:/stats/company/details
   */
  export namespace CompanyControllerDetails {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerDetailsData
  }

  /**
   * No description
   * @tags company
   * @name CompanyControllerYearCompare
   * @request GET:/stats/company/year-compare
   */
  export namespace CompanyControllerYearCompare {
    export type RequestParams = {}
    export type RequestQuery = {}
    export type RequestBody = never
    export type RequestHeaders = {}
    export type ResponseBody = CompanyControllerYearCompareData
  }
}

import type { AxiosInstance, AxiosRequestConfig, AxiosResponse, HeadersDefaults, ResponseType } from 'axios'
import axios from 'axios'

export type QueryParamsType = Record<string | number, any>

export interface FullRequestParams extends Omit<AxiosRequestConfig, 'data' | 'params' | 'url' | 'responseType'> {
  /** set parameter to `true` for call `securityWorker` for this request */
  secure?: boolean
  /** request path */
  path: string
  /** content type of request body */
  type?: ContentType
  /** query params */
  query?: QueryParamsType
  /** format of response (i.e. response.json() -> format: "json") */
  format?: ResponseType
  /** request body */
  body?: unknown
}

export type RequestParams = Omit<FullRequestParams, 'body' | 'method' | 'query' | 'path'>

export interface ApiConfig<SecurityDataType = unknown> extends Omit<AxiosRequestConfig, 'data' | 'cancelToken'> {
  securityWorker?: (
    securityData: SecurityDataType | null,
  ) => Promise<AxiosRequestConfig | void> | AxiosRequestConfig | void
  secure?: boolean
  format?: ResponseType
}

export enum ContentType {
  Json = 'application/json',
  FormData = 'multipart/form-data',
  UrlEncoded = 'application/x-www-form-urlencoded',
  Text = 'text/plain',
}

export class HttpClient<SecurityDataType = unknown> {
  public instance: AxiosInstance
  private securityData: SecurityDataType | null = null
  private securityWorker?: ApiConfig<SecurityDataType>['securityWorker']
  private secure?: boolean
  private format?: ResponseType

  constructor({ securityWorker, secure, format, ...axiosConfig }: ApiConfig<SecurityDataType> = {}) {
    this.instance = axios.create({ ...axiosConfig, baseURL: axiosConfig.baseURL || '' })
    this.secure = secure
    this.format = format
    this.securityWorker = securityWorker
  }

  public setSecurityData = (data: SecurityDataType | null) => {
    this.securityData = data
  }

  protected mergeRequestParams(params1: AxiosRequestConfig, params2?: AxiosRequestConfig): AxiosRequestConfig {
    const method = params1.method || (params2 && params2.method)

    return {
      ...this.instance.defaults,
      ...params1,
      ...(params2 || {}),
      headers: {
        ...((method && this.instance.defaults.headers[method.toLowerCase() as keyof HeadersDefaults]) || {}),
        ...(params1.headers || {}),
        ...((params2 && params2.headers) || {}),
      },
    }
  }

  protected stringifyFormItem(formItem: unknown) {
    if (typeof formItem === 'object' && formItem !== null) {
      return JSON.stringify(formItem)
    } else {
      return `${formItem}`
    }
  }

  protected createFormData(input: Record<string, unknown>): FormData {
    if (input instanceof FormData) {
      return input
    }
    return Object.keys(input || {}).reduce((formData, key) => {
      const property = input[key]
      const propertyContent: any[] = property instanceof Array ? property : [property]

      for (const formItem of propertyContent) {
        const isFileType = formItem instanceof Blob || formItem instanceof File
        formData.append(key, isFileType ? formItem : this.stringifyFormItem(formItem))
      }

      return formData
    }, new FormData())
  }

  public request = async <T = any, _E = any>({
    secure,
    path,
    type,
    query,
    format,
    body,
    ...params
  }: FullRequestParams): Promise<AxiosResponse<T>> => {
    const secureParams =
      ((typeof secure === 'boolean' ? secure : this.secure) &&
        this.securityWorker &&
        (await this.securityWorker(this.securityData))) ||
      {}
    const requestParams = this.mergeRequestParams(params, secureParams)
    const responseFormat = format || this.format || undefined

    if (type === ContentType.FormData && body && body !== null && typeof body === 'object') {
      body = this.createFormData(body as Record<string, unknown>)
    }

    if (type === ContentType.Text && body && body !== null && typeof body !== 'string') {
      body = JSON.stringify(body)
    }

    return this.instance.request({
      ...requestParams,
      headers: {
        ...(requestParams.headers || {}),
        ...(type ? { 'Content-Type': type } : {}),
      },
      params: query,
      responseType: responseFormat,
      data: body,
      url: path,
    })
  }
}

/**
 * @title Business Monitor
 * @version 1.0
 * @contact
 *
 * Business Monitor
 */
export class Api<SecurityDataType extends unknown> extends HttpClient<SecurityDataType> {
  email = {
    /**
     * @description Route for sending test email
     *
     * @tags email
     * @name EmailControllerSendTestEmail
     * @request GET:/email/test
     */
    emailControllerSendTestEmail: (params: RequestParams = {}) =>
      this.request<EmailControllerSendTestEmailData, any>({
        path: `/email/test`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for sending onboarding mail to specific users
     *
     * @tags email
     * @name EmailControllerSendOnboardingMailToSpecificUsers
     * @request GET:/email/onboarding-mail/specific/{userIds}
     */
    emailControllerSendOnboardingMailToSpecificUsers: (
      { userIds, ...query }: EmailControllerSendOnboardingMailToSpecificUsersParams,
      params: RequestParams = {},
    ) =>
      this.request<EmailControllerSendOnboardingMailToSpecificUsersData, any>({
        path: `/email/onboarding-mail/specific/${userIds}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags email
     * @name EmailControllerSendOnboardingMail
     * @request GET:/email/onboarding-mail/{companyId}
     */
    emailControllerSendOnboardingMail: (companyId: number, params: RequestParams = {}) =>
      this.request<EmailControllerSendOnboardingMailData, any>({
        path: `/email/onboarding-mail/${companyId}`,
        method: 'GET',
        ...params,
      }),
  }
  company = {
    /**
     * @description Public route for fetching all companys
     *
     * @tags company
     * @name CompanyControllerGetAllCompanys
     * @request GET:/company
     */
    companyControllerGetAllCompanys: (params: RequestParams = {}) =>
      this.request<CompanyControllerGetAllCompanysData, any>({
        path: `/company`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a company
     *
     * @tags company
     * @name CompanyControllerCreateCompany
     * @request POST:/company
     * @secure
     */
    companyControllerCreateCompany: (data: CreateCompanyDTO, params: RequestParams = {}) =>
      this.request<CompanyControllerCreateCompanyData, void>({
        path: `/company`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific company
     *
     * @tags company
     * @name CompanyControllerGetCompanyById
     * @request GET:/company/{id}
     */
    companyControllerGetCompanyById: (id: number, params: RequestParams = {}) =>
      this.request<CompanyControllerGetCompanyByIdData, void>({
        path: `/company/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific company
     *
     * @tags company
     * @name CompanyControllerUpdateCompany
     * @request PATCH:/company/{id}
     * @secure
     */
    companyControllerUpdateCompany: (id: number, data: UpdateCompanyDTO, params: RequestParams = {}) =>
      this.request<CompanyControllerUpdateCompanyData, void>({
        path: `/company/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific company
     *
     * @tags company
     * @name CompanyControllerDeleteCompany
     * @request DELETE:/company/{id}
     * @secure
     */
    companyControllerDeleteCompany: (id: number, params: RequestParams = {}) =>
      this.request<CompanyControllerDeleteCompanyData, void>({
        path: `/company/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Route for deleting a specific company
     *
     * @tags company
     * @name CompanyControllerChangeCompanyStatus
     * @request PATCH:/company/{id}/status
     * @secure
     */
    companyControllerChangeCompanyStatus: (id: number, data: ChangeStatusDTO, params: RequestParams = {}) =>
      this.request<CompanyControllerChangeCompanyStatusData, void>({
        path: `/company/${id}/status`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  employee = {
    /**
     * @description Public route for fetching all employees
     *
     * @tags employee
     * @name EmployeeControllerGetAllCompanyClients
     * @request GET:/employee
     */
    employeeControllerGetAllCompanyClients: (params: RequestParams = {}) =>
      this.request<EmployeeControllerGetAllCompanyClientsData, any>({
        path: `/employee`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a employee
     *
     * @tags employee
     * @name EmployeeControllerCreateEmployee
     * @request POST:/employee
     * @secure
     */
    employeeControllerCreateEmployee: (data: CreateEmployeeDTO, params: RequestParams = {}) =>
      this.request<EmployeeControllerCreateEmployeeData, void>({
        path: `/employee`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for exporting specific content data
     *
     * @tags employee
     * @name EmployeeControllerExportUsers
     * @request GET:/employee/export
     */
    employeeControllerExportUsers: (params: RequestParams = {}) =>
      this.request<EmployeeControllerExportUsersData, any>({
        path: `/employee/export`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for check import client template
     *
     * @tags employee
     * @name EmployeeControllerCheckImportTemplate
     * @request POST:/employee/import/template/check
     */
    employeeControllerCheckImportTemplate: (
      data: EmployeeControllerCheckImportTemplatePayload,
      params: RequestParams = {},
    ) =>
      this.request<EmployeeControllerCheckImportTemplateData, any>({
        path: `/employee/import/template/check`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Public route for importing client template
     *
     * @tags employee
     * @name EmployeeControllerImportTemplate
     * @request POST:/employee/import/template
     */
    employeeControllerImportTemplate: (data: EmployeeImportChangesDto, params: RequestParams = {}) =>
      this.request<EmployeeControllerImportTemplateData, any>({
        path: `/employee/import/template`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific employee
     *
     * @tags employee
     * @name EmployeeControllerGetEmployeeById
     * @request GET:/employee/{id}
     */
    employeeControllerGetEmployeeById: (id: number, params: RequestParams = {}) =>
      this.request<EmployeeControllerGetEmployeeByIdData, void>({
        path: `/employee/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific employee
     *
     * @tags employee
     * @name EmployeeControllerUpdateEmployee
     * @request PATCH:/employee/{id}
     * @secure
     */
    employeeControllerUpdateEmployee: (id: number, data: UpdateEmployeeDTO, params: RequestParams = {}) =>
      this.request<EmployeeControllerUpdateEmployeeData, void>({
        path: `/employee/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific employee
     *
     * @tags employee
     * @name EmployeeControllerDeleteEmployee
     * @request DELETE:/employee/{id}
     * @secure
     */
    employeeControllerDeleteEmployee: (id: number, params: RequestParams = {}) =>
      this.request<EmployeeControllerDeleteEmployeeData, void>({
        path: `/employee/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  salary = {
    /**
     * @description Public route for fetching all salarys
     *
     * @tags salary
     * @name SalaryControllerGetAllSalarys
     * @request GET:/salary
     */
    salaryControllerGetAllSalarys: (params: RequestParams = {}) =>
      this.request<SalaryControllerGetAllSalarysData, any>({
        path: `/salary`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a salary
     *
     * @tags salary
     * @name SalaryControllerCreateSalary
     * @request POST:/salary
     * @secure
     */
    salaryControllerCreateSalary: (data: CreateSalaryDTO, params: RequestParams = {}) =>
      this.request<SalaryControllerCreateSalaryData, void>({
        path: `/salary`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for exporting specific content data
     *
     * @tags salary
     * @name SalaryControllerExportUsers
     * @request GET:/salary/export
     */
    salaryControllerExportUsers: (params: RequestParams = {}) =>
      this.request<SalaryControllerExportUsersData, any>({
        path: `/salary/export`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for check import client template
     *
     * @tags salary
     * @name SalaryControllerCheckImportTemplate
     * @request POST:/salary/template/check
     */
    salaryControllerCheckImportTemplate: (
      data: SalaryControllerCheckImportTemplatePayload,
      params: RequestParams = {},
    ) =>
      this.request<SalaryControllerCheckImportTemplateData, any>({
        path: `/salary/template/check`,
        method: 'POST',
        body: data,
        type: ContentType.FormData,
        ...params,
      }),

    /**
     * @description Public route for importing client template
     *
     * @tags salary
     * @name SalaryControllerImportTemplate
     * @request POST:/salary/template
     */
    salaryControllerImportTemplate: (data: SalaryImportChangesDto, params: RequestParams = {}) =>
      this.request<SalaryControllerImportTemplateData, any>({
        path: `/salary/template`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific salary
     *
     * @tags salary
     * @name SalaryControllerGetSalaryById
     * @request GET:/salary/{id}
     */
    salaryControllerGetSalaryById: (id: number, params: RequestParams = {}) =>
      this.request<SalaryControllerGetSalaryByIdData, void>({
        path: `/salary/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific salary
     *
     * @tags salary
     * @name SalaryControllerUpdateSalary
     * @request PATCH:/salary/{id}
     * @secure
     */
    salaryControllerUpdateSalary: (id: number, data: UpdateSalaryDTO, params: RequestParams = {}) =>
      this.request<SalaryControllerUpdateSalaryData, void>({
        path: `/salary/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific salary
     *
     * @tags salary
     * @name SalaryControllerDeleteSalary
     * @request DELETE:/salary/{id}
     * @secure
     */
    salaryControllerDeleteSalary: (id: number, params: RequestParams = {}) =>
      this.request<SalaryControllerDeleteSalaryData, void>({
        path: `/salary/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching salaries for specific employee
     *
     * @tags salary
     * @name SalaryControllerGetSalariesByEmployeeId
     * @request GET:/salary/employee/{id}
     */
    salaryControllerGetSalariesByEmployeeId: (id: number, params: RequestParams = {}) =>
      this.request<SalaryControllerGetSalariesByEmployeeIdData, void>({
        path: `/salary/employee/${id}`,
        method: 'GET',
        ...params,
      }),
  }
  auth = {
    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSignIn
     * @request POST:/auth/signin
     */
    authControllerSignIn: (data: SignInDTO, params: RequestParams = {}) =>
      this.request<AuthControllerSignInData, void>({
        path: `/auth/signin`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerSingOut
     * @request GET:/auth/signout
     */
    authControllerSingOut: (params: RequestParams = {}) =>
      this.request<AuthControllerSingOutData, any>({
        path: `/auth/signout`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerResetPassword
     * @request POST:/auth/reset-password
     */
    authControllerResetPassword: (data: ResetPasswordDTO, params: RequestParams = {}) =>
      this.request<AuthControllerResetPasswordData, void>({
        path: `/auth/reset-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerForgotPassword
     * @request POST:/auth/forgot-password
     */
    authControllerForgotPassword: (data: ForgotPasswordDTO, params: RequestParams = {}) =>
      this.request<AuthControllerForgotPasswordData, void>({
        path: `/auth/forgot-password`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerGetMe
     * @request GET:/auth/me
     */
    authControllerGetMe: (token: uuid, params: RequestParams = {}) =>
      this.request<AuthControllerGetMeData, void>({
        path: `/auth/me`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags auth
     * @name AuthControllerUpdateMe
     * @request PATCH:/auth/me
     */
    authControllerUpdateMe: (token: uuid, data: UpdateUserDTO, params: RequestParams = {}) =>
      this.request<AuthControllerUpdateMeData, void>({
        path: `/auth/me`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),
  }
  user = {
    /**
     * No description
     *
     * @tags user
     * @name UserControllerInitiateUsers
     * @request GET:/user/initiate-users
     */
    userControllerInitiateUsers: (params: RequestParams = {}) =>
      this.request<UserControllerInitiateUsersData, any>({
        path: `/user/initiate-users`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags user
     * @name UserControllerHasPassword
     * @request GET:/user/has-password
     */
    userControllerHasPassword: (query: UserControllerHasPasswordParams, params: RequestParams = {}) =>
      this.request<UserControllerHasPasswordData, any>({
        path: `/user/has-password`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Public route for fetching all users
     *
     * @tags user
     * @name UserControllerGetAll
     * @request GET:/user
     */
    userControllerGetAll: (params: RequestParams = {}) =>
      this.request<UserControllerGetAllData, any>({
        path: `/user`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Admin route for creating a user
     *
     * @tags user
     * @name UserControllerCreateUser
     * @request POST:/user
     * @secure
     */
    userControllerCreateUser: (data: CreateUserDTO, params: RequestParams = {}) =>
      this.request<UserControllerCreateUserData, void>({
        path: `/user`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific users
     *
     * @tags user
     * @name UserControllerGetOneById
     * @request GET:/user/{id}
     */
    userControllerGetOneById: (id: number, params: RequestParams = {}) =>
      this.request<UserControllerGetOneByIdData, void>({
        path: `/user/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Admin route for creating a user
     *
     * @tags user
     * @name UserControllerUpdateUser
     * @request PATCH:/user/{id}
     * @secure
     */
    userControllerUpdateUser: (id: number, data: CreateUserDTO, params: RequestParams = {}) =>
      this.request<UserControllerUpdateUserData, void>({
        path: `/user/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),
  }
  department = {
    /**
     * @description Public route for fetching all departments
     *
     * @tags department
     * @name DepartmentControllerGetAllCompanyDepartments
     * @request GET:/department
     */
    departmentControllerGetAllCompanyDepartments: (params: RequestParams = {}) =>
      this.request<DepartmentControllerGetAllCompanyDepartmentsData, any>({
        path: `/department`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a department
     *
     * @tags department
     * @name DepartmentControllerCreateDepartment
     * @request POST:/department
     * @secure
     */
    departmentControllerCreateDepartment: (data: CreateDepartmentDTO, params: RequestParams = {}) =>
      this.request<DepartmentControllerCreateDepartmentData, void>({
        path: `/department`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific department
     *
     * @tags department
     * @name DepartmentControllerGetDepartmentById
     * @request GET:/department/{id}
     */
    departmentControllerGetDepartmentById: (id: number, params: RequestParams = {}) =>
      this.request<DepartmentControllerGetDepartmentByIdData, void>({
        path: `/department/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific department
     *
     * @tags department
     * @name DepartmentControllerUpdateDepartment
     * @request PATCH:/department/{id}
     * @secure
     */
    departmentControllerUpdateDepartment: (id: number, data: UpdateDepartmentDTO, params: RequestParams = {}) =>
      this.request<DepartmentControllerUpdateDepartmentData, void>({
        path: `/department/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific department
     *
     * @tags department
     * @name DepartmentControllerDeleteDepartment
     * @request DELETE:/department/{id}
     * @secure
     */
    departmentControllerDeleteDepartment: (id: number, params: RequestParams = {}) =>
      this.request<DepartmentControllerDeleteDepartmentData, void>({
        path: `/department/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific department
     *
     * @tags department
     * @name DepartmentControllerGetDepartmentByType
     * @request GET:/department/type/{type}
     */
    departmentControllerGetDepartmentByType: (type: string, params: RequestParams = {}) =>
      this.request<DepartmentControllerGetDepartmentByTypeData, void>({
        path: `/department/type/${type}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for setting a manager for a specific department
     *
     * @tags department
     * @name DepartmentControllerSetManager
     * @request PATCH:/department/set-manager/{employeeId}/departmentId/{departmentId}
     */
    departmentControllerSetManager: (departmentId: number, employeeId: number, params: RequestParams = {}) =>
      this.request<DepartmentControllerSetManagerData, void>({
        path: `/department/set-manager/${employeeId}/departmentId/${departmentId}`,
        method: 'PATCH',
        ...params,
      }),
  }
  workingHours = {
    /**
     * @description Public route for fetching all WorkingHours
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetAllCompanyWorkingHours
     * @request GET:/working-hours/department/{department}/date/{date}
     */
    workingHoursControllerGetAllCompanyWorkingHours: (department: string, date: string, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetAllCompanyWorkingHoursData, any>({
        path: `/working-hours/department/${department}/date/${date}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for fetching all WorkingHours
     *
     * @tags workingHours
     * @name WorkingHoursControllerDeleteAllCompanyWorkingHours
     * @request DELETE:/working-hours/department/{department}/date/{date}
     */
    workingHoursControllerDeleteAllCompanyWorkingHours: (
      department: string,
      date: string,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerDeleteAllCompanyWorkingHoursData, any>({
        path: `/working-hours/department/${department}/date/${date}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * @description Route for updating a specific workingHours
     *
     * @tags workingHours
     * @name WorkingHoursControllerUpdateWorkingHours
     * @request PATCH:/working-hours/{id}
     * @secure
     */
    workingHoursControllerUpdateWorkingHours: (id: number, data: UpdateWorkingHoursDTO, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerUpdateWorkingHoursData, void>({
        path: `/working-hours/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for creating a workingHours
     *
     * @tags workingHours
     * @name WorkingHoursControllerCreateWorkingHours
     * @request POST:/working-hours
     * @secure
     */
    workingHoursControllerCreateWorkingHours: (data: CreateWorkingHoursDTO, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerCreateWorkingHoursData, void>({
        path: `/working-hours`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetWorkingHoursByMonth
     * @request GET:/working-hours
     */
    workingHoursControllerGetWorkingHoursByMonth: (
      query: WorkingHoursControllerGetWorkingHoursByMonthParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetWorkingHoursByMonthData, any>({
        path: `/working-hours`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * @description Public route for fetching number of available hours in a month
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetAvailableHoursInMonth
     * @request GET:/working-hours/total-hours
     */
    workingHoursControllerGetAvailableHoursInMonth: (
      query: WorkingHoursControllerGetAvailableHoursInMonthParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetAvailableHoursInMonthData, any>({
        path: `/working-hours/total-hours`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetArchiveMonths
     * @request GET:/working-hours/archive
     */
    workingHoursControllerGetArchiveMonths: (params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetArchiveMonthsData, any>({
        path: `/working-hours/archive`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetActiveMonths
     * @request GET:/working-hours/active
     */
    workingHoursControllerGetActiveMonths: (params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetActiveMonthsData, any>({
        path: `/working-hours/active`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetEmployeeDepartments
     * @request GET:/working-hours/departments
     */
    workingHoursControllerGetEmployeeDepartments: (params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetEmployeeDepartmentsData, any>({
        path: `/working-hours/departments`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetClients
     * @request GET:/working-hours/clients
     */
    workingHoursControllerGetClients: (params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetClientsData, any>({
        path: `/working-hours/clients`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetWorkingHoursStatus
     * @request GET:/working-hours/status/{id}
     */
    workingHoursControllerGetWorkingHoursStatus: (id: number, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetWorkingHoursStatusData, any>({
        path: `/working-hours/status/${id}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetWorkingHoursStatusesByDate
     * @request GET:/working-hours/status/{date}/employee/{employeeId}
     */
    workingHoursControllerGetWorkingHoursStatusesByDate: (
      employeeId: number,
      date: string,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetWorkingHoursStatusesByDateData, any>({
        path: `/working-hours/status/${date}/employee/${employeeId}`,
        method: 'GET',
        format: 'json',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetNonWorkingHoursByMonth
     * @request GET:/working-hours/holiday
     */
    workingHoursControllerGetNonWorkingHoursByMonth: (
      query: WorkingHoursControllerGetNonWorkingHoursByMonthParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetNonWorkingHoursByMonthData, any>({
        path: `/working-hours/holiday`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerAddWorkingHour
     * @request POST:/working-hours/add-working-hour
     */
    workingHoursControllerAddWorkingHour: (data: AddWorkingHourDTO, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerAddWorkingHourData, any>({
        path: `/working-hours/add-working-hour`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerDeleteWorkingHour
     * @request DELETE:/working-hours/id/{whTypeId}
     */
    workingHoursControllerDeleteWorkingHour: (whTypeId: number, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerDeleteWorkingHourData, any>({
        path: `/working-hours/id/${whTypeId}`,
        method: 'DELETE',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerUpdateWorkingHour
     * @request PATCH:/working-hours/id/{whTypeId}
     */
    workingHoursControllerUpdateWorkingHour: (
      whTypeId: number,
      data: UpdateWorkingHoursTypeDTO,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerUpdateWorkingHourData, any>({
        path: `/working-hours/id/${whTypeId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetActiveMonthsManager
     * @request GET:/working-hours/manager/active/{departmentId}
     */
    workingHoursControllerGetActiveMonthsManager: (departmentId: number, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetActiveMonthsManagerData, any>({
        path: `/working-hours/manager/active/${departmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetNotSentMonthsManager
     * @request GET:/working-hours/manager/not-sent/{departmentId}
     */
    workingHoursControllerGetNotSentMonthsManager: (departmentId: number, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetNotSentMonthsManagerData, any>({
        path: `/working-hours/manager/not-sent/${departmentId}`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetArchiveMonthsManager
     * @request GET:/working-hours/manager/archive/{departmentId}
     */
    workingHoursControllerGetArchiveMonthsManager: (
      { departmentId, ...query }: WorkingHoursControllerGetArchiveMonthsManagerParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetArchiveMonthsManagerData, any>({
        path: `/working-hours/manager/archive/${departmentId}`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerApproveWorkingHour
     * @request PATCH:/working-hours/manager/approve/{whStatusId}
     */
    workingHoursControllerApproveWorkingHour: (whStatusId: number, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerApproveWorkingHourData, any>({
        path: `/working-hours/manager/approve/${whStatusId}`,
        method: 'PATCH',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerRejectWorkingHour
     * @request PATCH:/working-hours/manager/reject/{whStatusId}
     */
    workingHoursControllerRejectWorkingHour: (
      whStatusId: number,
      data: RejectWorkingHourStatus,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerRejectWorkingHourData, any>({
        path: `/working-hours/manager/reject/${whStatusId}`,
        method: 'PATCH',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetManagerDepartments
     * @request GET:/working-hours/manager/departments
     */
    workingHoursControllerGetManagerDepartments: (params: RequestParams = {}) =>
      this.request<WorkingHoursControllerGetManagerDepartmentsData, any>({
        path: `/working-hours/manager/departments`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetManagerClients
     * @request GET:/working-hours/manager/department/{departmentId}/clients
     */
    workingHoursControllerGetManagerClients: (
      { departmentId, ...query }: WorkingHoursControllerGetManagerClientsParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetManagerClientsData, any>({
        path: `/working-hours/manager/department/${departmentId}/clients`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGetExistingDates
     * @request GET:/working-hours/existing-dates
     */
    workingHoursControllerGetExistingDates: (
      query: WorkingHoursControllerGetExistingDatesParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGetExistingDatesData, any>({
        path: `/working-hours/existing-dates`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerSendStatusToManager
     * @request POST:/working-hours/status/send
     */
    workingHoursControllerSendStatusToManager: (data: SendStatusToManagerDTO, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerSendStatusToManagerData, any>({
        path: `/working-hours/status/send`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerSendReminder
     * @request POST:/working-hours/send-reminder
     */
    workingHoursControllerSendReminder: (data: StatusIdsDTO, params: RequestParams = {}) =>
      this.request<WorkingHoursControllerSendReminderData, any>({
        path: `/working-hours/send-reminder`,
        method: 'POST',
        body: data,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * No description
     *
     * @tags workingHours
     * @name WorkingHoursControllerGenerateStatuses
     * @request GET:/working-hours/generate-statuses
     */
    workingHoursControllerGenerateStatuses: (
      query: WorkingHoursControllerGenerateStatusesParams,
      params: RequestParams = {},
    ) =>
      this.request<WorkingHoursControllerGenerateStatusesData, any>({
        path: `/working-hours/generate-statuses`,
        method: 'GET',
        query: query,
        ...params,
      }),
  }
  client = {
    /**
     * @description Public route for fetching all clients
     *
     * @tags client
     * @name ClientControllerGetAllCompanyClients
     * @request GET:/client
     */
    clientControllerGetAllCompanyClients: (params: RequestParams = {}) =>
      this.request<ClientControllerGetAllCompanyClientsData, any>({
        path: `/client`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a client
     *
     * @tags client
     * @name ClientControllerCreateClient
     * @request POST:/client
     * @secure
     */
    clientControllerCreateClient: (data: CreateClientDTO, params: RequestParams = {}) =>
      this.request<ClientControllerCreateClientData, void>({
        path: `/client`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific client
     *
     * @tags client
     * @name ClientControllerGetClientById
     * @request GET:/client/{id}
     */
    clientControllerGetClientById: (id: number, params: RequestParams = {}) =>
      this.request<ClientControllerGetClientByIdData, void>({
        path: `/client/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific client
     *
     * @tags client
     * @name ClientControllerUpdateClient
     * @request PATCH:/client/{id}
     * @secure
     */
    clientControllerUpdateClient: (id: number, data: UpdateClientDTO, params: RequestParams = {}) =>
      this.request<ClientControllerUpdateClientData, void>({
        path: `/client/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific client
     *
     * @tags client
     * @name ClientControllerDeleteClient
     * @request DELETE:/client/{id}
     * @secure
     */
    clientControllerDeleteClient: (id: number, params: RequestParams = {}) =>
      this.request<ClientControllerDeleteClientData, void>({
        path: `/client/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  health = {
    /**
     * No description
     *
     * @tags health
     * @name HealthControllerCheckHealth
     * @request GET:/health
     */
    healthControllerCheckHealth: (params: RequestParams = {}) =>
      this.request<
        HealthControllerCheckHealthData,
        {
          /** @example "error" */
          status?: string
          /** @example {"database":{"status":"up"}} */
          info?: Record<
            string,
            {
              status?: string
              [key: string]: any
            }
          >
          /** @example {"redis":{"status":"down","message":"Could not connect"}} */
          error?: Record<
            string,
            {
              status?: string
              [key: string]: any
            }
          >
          /** @example {"database":{"status":"up"},"redis":{"status":"down","message":"Could not connect"}} */
          details?: Record<
            string,
            {
              status?: string
              [key: string]: any
            }
          >
        }
      >({
        path: `/health`,
        method: 'GET',
        format: 'json',
        ...params,
      }),
  }
  userCompany = {
    /**
     * @description Public route for fetching all userCompanys
     *
     * @tags UserCompany
     * @name UserCompanyControllerGetAllUsercompanys
     * @request GET:/user-company
     */
    userCompanyControllerGetAllUsercompanys: (params: RequestParams = {}) =>
      this.request<UserCompanyControllerGetAllUsercompanysData, any>({
        path: `/user-company`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a userCompany
     *
     * @tags UserCompany
     * @name UserCompanyControllerCreateUsercompany
     * @request POST:/user-company
     * @secure
     */
    userCompanyControllerCreateUsercompany: (data: CreateUserCompanyDTO, params: RequestParams = {}) =>
      this.request<UserCompanyControllerCreateUsercompanyData, void>({
        path: `/user-company`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific userCompany
     *
     * @tags UserCompany
     * @name UserCompanyControllerGetUsercompanyById
     * @request GET:/user-company/{id}
     */
    userCompanyControllerGetUsercompanyById: (id: number, params: RequestParams = {}) =>
      this.request<UserCompanyControllerGetUsercompanyByIdData, void>({
        path: `/user-company/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific userCompany
     *
     * @tags UserCompany
     * @name UserCompanyControllerUpdateUsercompany
     * @request PATCH:/user-company/{id}
     * @secure
     */
    userCompanyControllerUpdateUsercompany: (id: number, data: UpdateUserCompanyDTO, params: RequestParams = {}) =>
      this.request<UserCompanyControllerUpdateUsercompanyData, void>({
        path: `/user-company/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific userCompany
     *
     * @tags UserCompany
     * @name UserCompanyControllerDeleteUsercompany
     * @request DELETE:/user-company/{id}
     * @secure
     */
    userCompanyControllerDeleteUsercompany: (id: number, params: RequestParams = {}) =>
      this.request<UserCompanyControllerDeleteUsercompanyData, void>({
        path: `/user-company/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  pl = {
    /**
     * @description Public route for fetching all companypls
     *
     * @tags companypl
     * @name CompanyplControllerGetAllCompanypls
     * @request GET:/pl
     */
    companyplControllerGetAllCompanypls: (params: RequestParams = {}) =>
      this.request<CompanyplControllerGetAllCompanyplsData, any>({
        path: `/pl`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a companypl
     *
     * @tags companypl
     * @name CompanyplControllerCreateCompanypl
     * @request POST:/pl
     * @secure
     */
    companyplControllerCreateCompanypl: (data: CreateCompanyplDTO, params: RequestParams = {}) =>
      this.request<CompanyplControllerCreateCompanyplData, void>({
        path: `/pl`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific companypl
     *
     * @tags companypl
     * @name CompanyplControllerGetCompanyplById
     * @request GET:/pl/{id}
     */
    companyplControllerGetCompanyplById: (id: number, params: RequestParams = {}) =>
      this.request<CompanyplControllerGetCompanyplByIdData, void>({
        path: `/pl/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific companypl
     *
     * @tags companypl
     * @name CompanyplControllerUpdateCompanypl
     * @request PATCH:/pl/{id}
     * @secure
     */
    companyplControllerUpdateCompanypl: (id: number, data: UpdateCompanyplDTO, params: RequestParams = {}) =>
      this.request<CompanyplControllerUpdateCompanyplData, void>({
        path: `/pl/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific companypl
     *
     * @tags companypl
     * @name CompanyplControllerDeleteCompanypl
     * @request DELETE:/pl/{id}
     * @secure
     */
    companyplControllerDeleteCompanypl: (id: number, params: RequestParams = {}) =>
      this.request<CompanyplControllerDeleteCompanyplData, void>({
        path: `/pl/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific companypl
     *
     * @tags companypl
     * @name CompanyplControllerGetComapnyPlBtDate
     * @request GET:/pl/date/{date}
     */
    companyplControllerGetComapnyPlBtDate: (date: string, params: RequestParams = {}) =>
      this.request<CompanyplControllerGetComapnyPlBtDateData, void>({
        path: `/pl/date/${date}`,
        method: 'GET',
        ...params,
      }),
  }
  income = {
    /**
     * @description Public route for fetching all incomes
     *
     * @tags income
     * @name IncomeControllerGetAllIncomes
     * @request GET:/income
     */
    incomeControllerGetAllIncomes: (params: RequestParams = {}) =>
      this.request<IncomeControllerGetAllIncomesData, any>({
        path: `/income`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating incomes
     *
     * @tags income
     * @name IncomeControllerCreateIncomes
     * @request POST:/income
     * @secure
     */
    incomeControllerCreateIncomes: (data: CreateIncomesDTO, params: RequestParams = {}) =>
      this.request<IncomeControllerCreateIncomesData, void>({
        path: `/income`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching all incomes
     *
     * @tags income
     * @name IncomeControllerGetAllCompanyIncomes
     * @request GET:/income/company/{id}
     */
    incomeControllerGetAllCompanyIncomes: (id: number, params: RequestParams = {}) =>
      this.request<IncomeControllerGetAllCompanyIncomesData, any>({
        path: `/income/company/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for fetching all incomes at specified date
     *
     * @tags income
     * @name IncomeControllerGetAllCompanyIncomesByDate
     * @request GET:/income/date/{date}
     */
    incomeControllerGetAllCompanyIncomesByDate: (date: string, params: RequestParams = {}) =>
      this.request<IncomeControllerGetAllCompanyIncomesByDateData, any>({
        path: `/income/date/${date}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for deleting all incomes at specified date
     *
     * @tags income
     * @name IncomeControllerDeleteAllByDate
     * @request DELETE:/income/date/{date}
     */
    incomeControllerDeleteAllByDate: (date: string, params: RequestParams = {}) =>
      this.request<IncomeControllerDeleteAllByDateData, any>({
        path: `/income/date/${date}`,
        method: 'DELETE',
        ...params,
      }),
  }
  expense = {
    /**
     * @description Public route for fetching all expenses
     *
     * @tags expense
     * @name ExpenseControllerGetAllExpenses
     * @request GET:/expense
     */
    expenseControllerGetAllExpenses: (params: RequestParams = {}) =>
      this.request<ExpenseControllerGetAllExpensesData, any>({
        path: `/expense`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating expenses
     *
     * @tags expense
     * @name ExpenseControllerCreateExpenses
     * @request POST:/expense
     * @secure
     */
    expenseControllerCreateExpenses: (data: CreateExpensesDTO, params: RequestParams = {}) =>
      this.request<ExpenseControllerCreateExpensesData, void>({
        path: `/expense`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching all expenses
     *
     * @tags expense
     * @name ExpenseControllerGetAllCompanyExpenses
     * @request GET:/expense/company/{id}
     */
    expenseControllerGetAllCompanyExpenses: (id: number, params: RequestParams = {}) =>
      this.request<ExpenseControllerGetAllCompanyExpensesData, any>({
        path: `/expense/company/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for fetching all incomes at specified date
     *
     * @tags expense
     * @name ExpenseControllerGetAllCompanyIncomesByDate
     * @request GET:/expense/date/{date}
     */
    expenseControllerGetAllCompanyIncomesByDate: (date: string, params: RequestParams = {}) =>
      this.request<ExpenseControllerGetAllCompanyIncomesByDateData, any>({
        path: `/expense/date/${date}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Public route for deleting all expenses at specified date
     *
     * @tags expense
     * @name ExpenseControllerDeleteAllByDate
     * @request DELETE:/expense/date/{date}
     */
    expenseControllerDeleteAllByDate: (date: string, params: RequestParams = {}) =>
      this.request<ExpenseControllerDeleteAllByDateData, any>({
        path: `/expense/date/${date}`,
        method: 'DELETE',
        ...params,
      }),
  }
  faq = {
    /**
     * @description Public route for fetching all faqs
     *
     * @tags faq
     * @name FaqControllerGetAllFaqs
     * @request GET:/faq
     */
    faqControllerGetAllFaqs: (params: RequestParams = {}) =>
      this.request<FaqControllerGetAllFaqsData, any>({
        path: `/faq`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for creating a faq
     *
     * @tags faq
     * @name FaqControllerCreateFaq
     * @request POST:/faq
     * @secure
     */
    faqControllerCreateFaq: (data: CreateFaqDTO, params: RequestParams = {}) =>
      this.request<FaqControllerCreateFaqData, void>({
        path: `/faq`,
        method: 'POST',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Public route for fetching a specific faq
     *
     * @tags faq
     * @name FaqControllerGetFaqById
     * @request GET:/faq/{id}
     */
    faqControllerGetFaqById: (id: number, params: RequestParams = {}) =>
      this.request<FaqControllerGetFaqByIdData, void>({
        path: `/faq/${id}`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for updating a specific faq
     *
     * @tags faq
     * @name FaqControllerUpdateFaq
     * @request PATCH:/faq/{id}
     * @secure
     */
    faqControllerUpdateFaq: (id: number, data: UpdateFaqDTO, params: RequestParams = {}) =>
      this.request<FaqControllerUpdateFaqData, void>({
        path: `/faq/${id}`,
        method: 'PATCH',
        body: data,
        secure: true,
        type: ContentType.Json,
        ...params,
      }),

    /**
     * @description Route for deleting a specific faq
     *
     * @tags faq
     * @name FaqControllerDeleteFaq
     * @request DELETE:/faq/{id}
     * @secure
     */
    faqControllerDeleteFaq: (id: number, params: RequestParams = {}) =>
      this.request<FaqControllerDeleteFaqData, void>({
        path: `/faq/${id}`,
        method: 'DELETE',
        secure: true,
        ...params,
      }),
  }
  stats = {
    /**
     * No description
     *
     * @tags dash
     * @name DashControllerIncomeAndCosts
     * @request GET:/stats/dash/income-and-costs
     */
    dashControllerIncomeAndCosts: (params: RequestParams = {}) =>
      this.request<DashControllerIncomeAndCostsData, void>({
        path: `/stats/dash/income-and-costs`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dash
     * @name DashControllerEmployeeAndClient
     * @request GET:/stats/dash/employee-and-client
     */
    dashControllerEmployeeAndClient: (query: DashControllerEmployeeAndClientParams, params: RequestParams = {}) =>
      this.request<DashControllerEmployeeAndClientData, void>({
        path: `/stats/dash/employee-and-client`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags dash
     * @name DashControllerDepartmentReview
     * @request GET:/stats/dash/department-review
     */
    dashControllerDepartmentReview: (params: RequestParams = {}) =>
      this.request<DashControllerDepartmentReviewData, void>({
        path: `/stats/dash/department-review`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags dash
     * @name DashControllerTopClients
     * @request GET:/stats/dash/top-clients
     */
    dashControllerTopClients: (query: DashControllerTopClientsParams, params: RequestParams = {}) =>
      this.request<DashControllerTopClientsData, void>({
        path: `/stats/dash/top-clients`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ClientControllerSummary
     * @request GET:/stats/client/summary
     */
    clientControllerSummary: (params: RequestParams = {}) =>
      this.request<ClientControllerSummaryData, void>({
        path: `/stats/client/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ClientControllerList
     * @request GET:/stats/client/list
     */
    clientControllerList: (params: RequestParams = {}) =>
      this.request<ClientControllerListData, void>({
        path: `/stats/client/list`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ClientControllerClientSummary
     * @request GET:/stats/client/{id}/summary
     */
    clientControllerClientSummary: (id: number, params: RequestParams = {}) =>
      this.request<ClientControllerClientSummaryData, void>({
        path: `/stats/client/${id}/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ClientControllerClientOverview
     * @request GET:/stats/client/{id}/overview
     */
    clientControllerClientOverview: (id: number, params: RequestParams = {}) =>
      this.request<ClientControllerClientOverviewData, void>({
        path: `/stats/client/${id}/overview`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags client
     * @name ClientControllerYearCompare
     * @request GET:/stats/client/{id}/year-compare
     */
    clientControllerYearCompare: (id: number, params: RequestParams = {}) =>
      this.request<ClientControllerYearCompareData, void>({
        path: `/stats/client/${id}/year-compare`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags department
     * @name DepartmentControllerSummary
     * @request GET:/stats/department/summary
     */
    departmentControllerSummary: (params: RequestParams = {}) =>
      this.request<DepartmentControllerSummaryData, void>({
        path: `/stats/department/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags department
     * @name DepartmentControllerList
     * @request GET:/stats/department/list
     */
    departmentControllerList: (params: RequestParams = {}) =>
      this.request<DepartmentControllerListData, void>({
        path: `/stats/department/list`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags department
     * @name DepartmentControllerCompare
     * @request GET:/stats/department/year-compare
     */
    departmentControllerCompare: (params: RequestParams = {}) =>
      this.request<DepartmentControllerCompareData, void>({
        path: `/stats/department/year-compare`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerSummary
     * @request GET:/stats/employee/summary
     */
    employeeControllerSummary: (params: RequestParams = {}) =>
      this.request<EmployeeControllerSummaryData, void>({
        path: `/stats/employee/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerClientEmployees
     * @request GET:/stats/employee/client-employees
     */
    employeeControllerClientEmployees: (params: RequestParams = {}) =>
      this.request<EmployeeControllerClientEmployeesData, void>({
        path: `/stats/employee/client-employees`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerClientDepartments
     * @request GET:/stats/employee/client-departments
     */
    employeeControllerClientDepartments: (params: RequestParams = {}) =>
      this.request<EmployeeControllerClientDepartmentsData, void>({
        path: `/stats/employee/client-departments`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerEmployeeSummary
     * @request GET:/stats/employee/{id}/summary
     */
    employeeControllerEmployeeSummary: (id: number, params: RequestParams = {}) =>
      this.request<EmployeeControllerEmployeeSummaryData, void>({
        path: `/stats/employee/${id}/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerEmployeeClientDetails
     * @request GET:/stats/employee/{id}/details
     */
    employeeControllerEmployeeClientDetails: (id: number, params: RequestParams = {}) =>
      this.request<EmployeeControllerEmployeeClientDetailsData, void>({
        path: `/stats/employee/${id}/details`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerVacationHours
     * @request GET:/stats/employee/vacation-hours
     */
    employeeControllerVacationHours: (params: RequestParams = {}) =>
      this.request<EmployeeControllerVacationHoursData, void>({
        path: `/stats/employee/vacation-hours`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerMaternityHours
     * @request GET:/stats/employee/maternity-hours
     */
    employeeControllerMaternityHours: (params: RequestParams = {}) =>
      this.request<EmployeeControllerMaternityHoursData, void>({
        path: `/stats/employee/maternity-hours`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags employee
     * @name EmployeeControllerSickHours
     * @request GET:/stats/employee/sick-hours
     */
    employeeControllerSickHours: (params: RequestParams = {}) =>
      this.request<EmployeeControllerSickHoursData, void>({
        path: `/stats/employee/sick-hours`,
        method: 'GET',
        ...params,
      }),

    /**
     * @description Route for getting number of employees in a specific department
     *
     * @tags employee
     * @name EmployeeControllerGetNumberOfEmployeesInDepartment
     * @request GET:/stats/employee/{id}/number-of-employees
     */
    employeeControllerGetNumberOfEmployeesInDepartment: (
      { id, ...query }: EmployeeControllerGetNumberOfEmployeesInDepartmentParams,
      params: RequestParams = {},
    ) =>
      this.request<EmployeeControllerGetNumberOfEmployeesInDepartmentData, any>({
        path: `/stats/employee/${id}/number-of-employees`,
        method: 'GET',
        query: query,
        ...params,
      }),

    /**
     * No description
     *
     * @tags company
     * @name CompanyControllerSummary
     * @request GET:/stats/company/summary
     */
    companyControllerSummary: (params: RequestParams = {}) =>
      this.request<CompanyControllerSummaryData, void>({
        path: `/stats/company/summary`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags company
     * @name CompanyControllerDetails
     * @request GET:/stats/company/details
     */
    companyControllerDetails: (params: RequestParams = {}) =>
      this.request<CompanyControllerDetailsData, void>({
        path: `/stats/company/details`,
        method: 'GET',
        ...params,
      }),

    /**
     * No description
     *
     * @tags company
     * @name CompanyControllerYearCompare
     * @request GET:/stats/company/year-compare
     */
    companyControllerYearCompare: (params: RequestParams = {}) =>
      this.request<CompanyControllerYearCompareData, void>({
        path: `/stats/company/year-compare`,
        method: 'GET',
        ...params,
      }),
  }
}
