import { INotificationStore, notificationStore } from '@stores/notification.store'
import { useCallback, useEffect, useState } from 'react'
import { createPortal } from 'react-dom'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'

const titleStyle = (status: 'info' | 'warning' | 'error' | 'success' = 'info') => {
  switch (status) {
    case 'info':
      return tw`text-table-accent-blue`
    case 'error':
      return tw`text-error`
    case 'warning':
      return tw`text-warning`
    case 'success':
      return tw`text-success`
    default:
      return tw`text-table-accent-blue`
  }
}

export const Notifications = () => {
  const [{ notifications, updateNotification, removeNotification, getNotification, closeNotification }, setNots] =
    useState<INotificationStore>(notificationStore.getState())
  notificationStore.subscribe(n => setNots(nots => ({ ...nots, notifications: n.notifications })))

  const { t } = useTranslation()

  useEffect(() => {
    if (notifications.length) {
      notifications.forEach(not => {
        if (not.mounted && !not.timerStarted && not.autoClose) {
          setTimeout(() => {
            updateNotification(not.key, { mounted: false })
          }, 3000)
          updateNotification(not.key, { timerStarted: true })
        }
      })
    }
  }, [notifications])

  const updateRef = useCallback((node: any, key: number) => {
    const not = getNotification(key)
    if (not != null && !not.mounted && !not.timerStarted) {
      setTimeout(() => updateNotification(key, { mounted: true }), 50)
    }
  }, [])

  return createPortal(
    <div tw='fixed top-0 left-0 flex flex-col py-3 transition-all z-[999950]'>
      {notifications.map((val, _) => (
        <div
          key={val.key}
          ref={node => updateRef(node, val.key)}
          tw='relative mb-2 rounded-lg bg-white py-6 px-5 shadow-black shadow-sm transition-all w-[320px]'
          css={[!val.mounted ? tw`-translate-x-full` : tw`ml-2`]}
          onTransitionEnd={() => {
            const not = getNotification(val.key)
            if (not != null && not.timerStarted && !not.mounted) {
              removeNotification(val.key)
            }
          }}
        >
          <div tw='mb-2 flex justify-between'>
            <span tw='text-lg' css={[titleStyle(val.status)]}>
              {val.title ?? t(`status.${val.status}`)}
            </span>
            <i
              tw='cursor-pointer text-lg text-gray-400'
              className='fa fa-close'
              onClick={() => closeNotification(val.key)}
            />
          </div>
          <span>{Array.isArray(val.content) ? val.content.map((c: any) => <div key={c}>{c}</div>) : val.content}</span>
        </div>
      ))}
    </div>,
    document.body,
  )
}
