import { TextInput } from '@components/inputs'
import { Button } from '@components/ui'
import { zodResolver } from '@hookform/resolvers/zod'
import { FC } from 'react'
import { FieldValues, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import z from 'zod'
import { Form } from '../Form/Form'

interface IResetPasswordFormProps {
  onSubmit: (values: FieldValues) => void
}

const ResetPasswordFormSchema = z
  .object({
    password: z.string().min(8, 'Lozinka mora sadržavati najmanje 8 znakova'),
    confirmPassword: z.string().min(8, 'Lozinka mora sadržavati najmanje 8 znakova'),
  })
  .refine(data => data.password === data.confirmPassword, {
    message: 'Lozinke se ne podudaraju',
    path: ['confirmPassword'],
  })

export const ResetPasswordForm: FC<IResetPasswordFormProps> = ({ onSubmit }) => {
  const { t } = useTranslation()
  const methods = useForm({
    resolver: zodResolver(ResetPasswordFormSchema),
    reValidateMode: 'onBlur',
  })
  const { handleSubmit } = methods

  return (
    <Form methods={methods} onSubmit={handleSubmit(onSubmit)}>
      <TextInput id='password' label={t('login.password')} type='password' />
      <TextInput id='confirmPassword' label={t('login.confirm_password')} type='password' />
      <Button tw='mt-2 w-full uppercase' type='submit'>
        {t('login.reset_password')}
      </Button>
    </Form>
  )
}
