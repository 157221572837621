export const publicRoutes = {
  LOGIN_PATH: '/login',
  FORGOT_PASSWORD: '/forgot-password',
  RESET_PASSWORD: '/reset-password/:token',
  NOT_FOUND_PATH: '/*',
  EMPTY: '',
}

export const privateRoutes = {
  DASHBOARD_PATH: '/dashboard',
  CLIENTS_PATH: '/clients',
  CLIENT_PATH: '/clients/:id',
  COMPANY_LIST_PATH: '/company-list',
  COMPANY_SETTINGS_PATH: '/company-list/:id',
  DEPARTMENTS_PATH: '/departments',
  EDIT_DEPARTMENTS_PATH: '/edit-departments',
  EMPLOYEES_PATH: '/employees',
  EMPLOYEE_PATH: '/employees/:id',
  MEDIAS_PATH: '/media',
  MEDIA_PATH: '/media/:id',
  COMPANY_PATH: '/company',
  FAQ_PATH: '/faq',
  ACCOUNT_PATH: '/account',
  WORKING_HOURS: '/working-hours',
  WORKING_HOURS_MONTH: '/working-hours/:id',
  WORKING_HOURS_MONTH_EMPLOYEE: '/working-hours/:id/employee/:employeeId',
  WORKING_HOURS_MANAGEMENT: '/working-hours-management',
  INCOME_AND_COSTS_PATH: '/income-costs',
  P_AND_L_PATH: '/p-and-l',
  // WORKING_HOURS_MONTH: '/working-hours/:id',
  NOT_FOUND_PATH: '/*',
  EMPTY: '',
}
