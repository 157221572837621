import { FC, InputHTMLAttributes, RefObject } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'

interface ISearchInputProps extends InputHTMLAttributes<HTMLInputElement> {
  label?: string | null
  searchRef?: RefObject<HTMLInputElement>
}

export const DropdownSearch: FC<ISearchInputProps> = ({ label, searchRef, ...inputProps }) => {
  const { t } = useTranslation()
  return (
    <div className='border-accent focus:(text-gray-700 border-primary) relative flex items-center gap-2 overflow-hidden rounded-t border border-solid bg-neutral-100 pl-3'>
      <i className='fa fa-search' tw='absolute text-icons' />
      <input
        className='py-2 pl-6 text-sm transition-colors'
        tw='bg-neutral-100 outline-none'
        placeholder={t('shared.search') as string}
        ref={searchRef}
        {...inputProps}
      />
    </div>
  )
}
