import { axiosApiInstance } from '@api/axiosInstance'
import { UpdateClientDTO } from '@api/generated-api'
import { ComponentWrapper, ITableData, Table } from '@components/ui'
import { useClientsList } from '@hooks/api/client'
import { useNavigation } from '@hooks/useNavigation'
import { useOrder } from '@hooks/useOrder'
import { useMe } from '@stores/me.store'
import dayjs from 'dayjs'
import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import 'twin.macro'
import tw from 'twin.macro'
import { IClient } from '../../../../types/client'
import { ConfirmationModal } from '../../../components/ConfirmationModal'
import { EditDeleteRender } from '../../../components/Renders/EditDeleteRender'
import { NotificationModal } from '../../WorkingHours/Components/NotificationModal'
import { UpdateClientsModal } from './UpdateClientsModal'

export const ClientsTable = () => {
  const { me } = useMe()
  const [page, setPage] = useState(1)
  const [search, setSearch] = useState('')
  const [order, setOrder, orderData] = useOrder({ key: 'name', direction: 'ASC' })
  const { t } = useTranslation()
  const { client } = useNavigation()
  const { client: clientApi } = axiosApiInstance
  const [isDeleteOpen, setIsDeleteOpen] = useState<undefined | ITableData>(undefined)
  const [isEditOpen, setIsEditOpen] = useState<undefined | ITableData>(undefined)
  const [isSuccessfulEditOpen, setIsSuccessfulEditOpen] = useState<boolean>(false)
  const { data, isLoading, refetch } = useClientsList()

  const tableData = (data?.data ?? [])
    .map(v => ({ ...v, key: v.id }))
    .filter(v =>
      Object.values(v).reduce(
        (acc, v) => acc || (typeof v == 'string' && v.toLowerCase().startsWith(search.toLowerCase())),
        false,
      ),
    )
    .sort(orderData)

  const update = async (client: UpdateClientDTO) => {
    const resp = await clientApi.clientControllerUpdateClient(client.id, client)
    if (resp?.data?.affected > 0) {
      setIsEditOpen(undefined)
      setIsSuccessfulEditOpen(true)
      refetch()
    }
  }

  const delClient = async () => {
    await clientApi.clientControllerDeleteClient(isDeleteOpen?.id)
    setIsDeleteOpen(undefined)
    refetch()
  }

  return (
    <ComponentWrapper loading={isLoading}>
      <div tw='overflow-scroll'>
        <Table
          search={(search?: string) => setSearch(search ?? '')}
          title={t('clients.list') as string}
          pagination={{
            currentPage: page,
            onPageChange: setPage,
            perPage: 30,
            total: tableData.length,
          }}
          order={order}
          columns={[
            {
              key: 'name',
              dataIndex: 'name',
              title: t('shared.client'),
              order: setOrder,
            },
            {
              key: 'created_at',
              dataIndex: 'created_at',
              title: t('shared.created_at'),
              order: setOrder,
              render: data => <>{dayjs(data['created_at']).format('YYYY-MM-DD')}</>,
            },
            {
              key: 'status',
              dataIndex: 'status',
              decoration: tw`w-[150px]`,
              title: t('shared.status'),
              order: setOrder,
              render: data => <>{data?.deleted_at ? 'Neaktivan' : 'Aktivan'}</>,
            },
            {
              key: 'trash',
              dataIndex: 'trash',
              title: '',
              decoration: tw`w-[100px]`,
              render: data => (
                <EditDeleteRender onEdit={() => setIsEditOpen(data)} onDelete={() => setIsDeleteOpen(data)} />
              ),
            },
            // {
            //   key: 'income',
            //   dataIndex: 'income',
            //   title: t('shared.income'),
            //   order: setOrder,
            //   render: data => <>{formatCurrency(data['income'])}</>,
            // },
            // {
            //   key: 'expense',
            //   dataIndex: 'expense',
            //   title: t('shared.costs'),
            //   order: setOrder,
            //   render: data => <>{formatCurrency(data['expense'])}</>,
            // },
            // {
            //   key: 'profit',
            //   dataIndex: 'profit',
            //   title: t('shared.profit'),
            //   order: setOrder,
            //   render: data => <>{formatCurrency(data['profit'])}</>,
            // },
          ]}
          data={tableData}
          onRowClick={me?.isAdmin ? (id, row) => (row?.deleted_at ? undefined : client(id)) : undefined}
        />
      </div>
      <NotificationModal
        isOpen={!!isDeleteOpen}
        onClose={() => setIsDeleteOpen(undefined)}
        title={'Obriši klijenta'}
        subtitle={'Potvrđivanjem radnje svi podaci vezani za ovog klijenta bit će trajno obrisani.'}
        onPrimaryClick={delClient}
        secondaryButtonText={t('shared.give_up') as string}
        primaryButtonText={t('shared.confirm') as string}
      />
      {/*<CreateClientModal isOpen={!!isCreateOpen} close={() => setIsCreateOpen(undefined)} createClient={create} />*/}
      <UpdateClientsModal
        isOpen={!!isEditOpen}
        close={() => setIsEditOpen(undefined)}
        updateClients={update}
        initialValue={isEditOpen && { ...(isEditOpen as IClient) }}
      />
      <ConfirmationModal
        title={'Promjene su uspješno spremljene!'}
        onClose={() => setIsSuccessfulEditOpen(false)}
        isOpen={isSuccessfulEditOpen}
      />
    </ComponentWrapper>
  )
}
